import React from 'react';
import {Link} from 'react-router-dom';


class JacobsCreek extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav jacobs-creek" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper jacobs-creek__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >Jacob&#x27;s Creek</h6>
                                    <h2 className="type-bloc-headline" >Обновление сайта Jacob's Creek</h2>
                                    <hr className="c-hr c-hr--jc c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >
                                        Изменение восприятия глобальной иконы винного дела</h3></div>
                            </header>

                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                Проблема Jacob’s Creek заключалась в текущем облике бренда. К сожалению, продукт воспринимался как масовое вино.
                                Мы взяли на себя ответсвенность пересоздать образ вина и компании в цифровом пространстве. Cделать все чтобы клиенты полюбили продукт.
                            </h3>
                            <hr className="c-hr c-hr--jc c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited" >
                            <figure className="case-study-figure" >
                                <div >
                                    <video autoPlay loop muted className="u-image-restraints js-controls-video" >
                                        <source src="/static/vids/case-studies/jacobs-creek.webm" type="video/webm" />
                                        <source src="/static/vids/case-studies/jacobs-creek.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                Перезапуск образа бренда в цифровом мире, поспособствовал развитию страсти к компании Jacob’s Creek.
                            </h3>
                            <hr className="c-hr c-hr--jc c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >

                                Мы хотели показать, что несмотря на крупный размер компании в ней существует сердце присущее ременсленникам. Вино, которое они производят, выращивают, собирают, и выдерживают производится с настоящей страстью.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/jacobs-creek/pages1.jpg" className="u-image-restraints" /></section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                Работа включала в себя разработку нового дизайна, верстку, фотографии, видео и копирайтинг.
                            </h3>
                            <hr className="c-hr c-hr--jc c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/jacobs-creek/pages2.jpg" className="u-image-restraints" /></section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/jacobs-creek/pages3.jpg" className="u-image-restraints" /></section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                               Мы создали кастомную CMS для решения задач нашего клиента.
                            </h3>
                            <hr className="c-hr c-hr--jc c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >
                                Вместо того, чтобы пытаться объединить сложные задачи бизнеса,
                                который продает более 150 различных сортов вин на 40 рынках на 5 разных языках,
                                мы создали пользовательскую CMS, которая обеспечивала целостность дизайна бренда,
                                при этом позволила обеспечить высокий уровень контроля на рынке сбыта.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/jacobs-creek/cms.jpg" className="u-image-restraints" /></section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Немного о системе управления контентом</h3>
                            <hr className="c-hr c-hr--jc c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >
                                Чтобы расширить возможности сайта мы, создали кастомную CMS для управления контентом и интегрировались с eCRM
                                для эффективного управления заказами.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/jacobs-creek/our-table.png" className="u-image-restraints" /></section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/jacobs-creek/our-table-pages.jpg" className="u-image-restraints" /></section>

                </div>
                <div className=".case-studies-reel" >
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek" to="/case-studies/jacobs-creek" >
                        <h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons" >Обновление сайта Jacob's Creek</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/case-studies/made-by" >
                        <h4 className="type-block-sub-headline" >Made By</h4>
                        <h6 className="type-subheadlines-buttons" >Создание истории бренда «Made By»</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/case-studies/rjsmith" >
                        <h4 className="type-block-sub-headline" >RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons" >Создание бренда</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/case-studies" >
                        <h4 className="type-block-sub-headline" >Кровь, Пот &amp; Слезы</h4>
                        <h6 className="type-subheadlines-buttons" >Посмотреть все наши работы.</h6>
                    </Link>
                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (JacobsCreek);
