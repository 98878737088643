import React from 'react';
import { Link } from 'react-router-dom';

class FullLanding extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav seven-up" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper brancott-estate__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >Need-Cars</h6>
                                    <h2 className="type-bloc-headline" >
                                        <div >Помощь в выборе автомобиля с пробегом</div>
                                    </h2>
                                    <hr className="c-hr c-hr--seven-up c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >Создание сайта компании</h3>
                                </div>
                            </header>
                            <div className="sub-header" >
                                <h3 className="type-block-sub-headline2" >
                                    Все так или иначе сталкивались с выбором автомобиля.
                                    Комания Need-Cars занимается подбором автомобилей уже много лет и накопила огромный экспертный опыт работы.
                                </h3>
                                <hr className="c-hr c-hr--seven-up c-hr--large" />
                            </div>
                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Задачи, поставленные в проекте перед нами.</h3>
                            <hr className="c-hr c-hr--seven-up c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >
                                Нам необходимо было выполнить работы по созданию дизайна, структуры и сайта.
                                Основными требованиями заказчика были: простота офромления,
                                функциональные формы обратной связи и заявки на получение услуги.
                                Целью проекта является создание площадки, на которой возможно ознакомиться с работами компании и сделать заказ на получение услуги.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited u-no-padding-below-desktop" >
                            <figure className="case-study-figure" >
                                <div >
                                    <video autoPlay muted loop className="u-image-restraints js-controls-video" >
                                        <source src="/static/vids/case-studies/needcars/needcars.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                В результате нашей работы мы создали сайт комании, отвечающий всем требованиям заказчика.
                                Так же разработаны почтовые рассылки для клиентов компании.
                                Благодаря слаженной совместной работе заказчика и наших специалистов нам удалось создать качественный продукт в условиях ограниченного бюджета.
                                Хотелось бы поблагодарить руководителей компании за здоровый перфекционизим близкий нам.
                            </h3>
                            <hr className="c-hr c-hr--seven-up c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <img src="/static/img/case-studies/needcars/preview.jpg" alt="7 Up website preview in several devices." className="u-image-full-width case-study-reveal-large-image" />
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                Даже после завершения задачи и сдачи проекта, мы остаемся на связи с нашими клиентами и по их желанию дорабатываем продукты по мере необходимости.
                            </h3>
                            <hr className="c-hr c-hr--seven-up c-hr--large" />
                        </div>
                    </section>
                </div>
                <div className=".case-studies-reel" >
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek" to="/case-studies/jacobs-creek" >
                        <h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons" >Обновление сайта Jacob's Creek</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/case-studies/made-by" >
                        <h4 className="type-block-sub-headline" >Made By</h4>
                        <h6 className="type-subheadlines-buttons" >Создание истории бренда «Made By»</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/case-studies/rjsmith" >
                        <h4 className="type-block-sub-headline" >RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons" >Создание бренда</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/case-studies" >
                        <h4 className="type-block-sub-headline" >Кровь, Пот &amp; Слезы</h4>
                        <h6 className="type-subheadlines-buttons" >Посмотреть все наши работы.</h6>
                    </Link>
                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (FullLanding);
