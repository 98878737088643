import React from 'react';
import classNames from 'classnames';

class Navigator extends React.Component {

    constructor() {
        super();
    }


    render() {

        return (
            <div className="page-jobs-landing" >
                <div className="u-width-limiter" >
                    <header className="jobs-landing-heading" >
                        <h1 className="type-subheadlines-buttons" >Один шаг к величию</h1>
                        <h2 className="type-hero-headline" >
                            <div >Получите свой
                                <br/>кусок успеха
                            </div>
                        </h2>
                        <hr className="c-hr c-hr--white c-hr--large" />
                    </header>
                    <div className="c-job-list" >
                        <ul className="c-job-list__list" >
                            <li className="c-job-list__item" >
                                <h4 className="type-block-sub-headline" > МЕНЕДЖЕР ПРОЕКТОВ,
                                    Москва</h4>
                                <p className="type-body-copy" >

                                    Вы будете заниматься организацией работы, которая будет вам
                                    нравиться. У вас будет опыт работы с проектами со всего цифрового спектра. Более
                                    того, у вас будет желание изучать новые инструменты разработки продуктов.

                                </p>
                                <hr className="c-hr c-hr--black" />
                            </li>
                            <li className="c-job-list__item" >
                                <h4 className="type-block-sub-headline" >
                                    Рекламный менеджер, Москва</h4>
                                <p className="type-body-copy" >

                                    Мы являемся быстро развивающимся агентством, работающим с рядом интересных клиентов
                                    наряду с разнообразным спектром используемых инструментов. Наш быстрый рост
                                    агентства требует, эффективного  рекламного менеджера чтобы он
                                    успешно управлял ресурсом студии на разных этапах жизненного проекта.

                                </p>

                                <hr className="c-hr c-hr--black" />
                            </li>
                            <li className="c-job-list__item" >
                                <h4 className="type-block-sub-headline" >МЕНЕДЖЕР ПО РАБОТЕ С
                                    КЛИЕНТАМИ, Москва</h4>
                                <p className="type-body-copy" >

                                    В качестве менеджера по работе с клиентами в рамках одной из наших групп управления
                                    клиентами вы будете отвечать за повседневную работу ваших учетных записей, включая
                                    создание отношений с бренд-менеджерами, сквозное управление кампаниями и
                                    стратегическое развитие учетных записей.

                                </p>

                                <hr className="c-hr c-hr--black" />
                            </li>
                            <li className="c-job-list__item" >
                                <h4 className="type-block-sub-headline" >МЕНЕДЖЕР КОНТЕНТА,
                                    Санкт-Петербург</h4>
                                <p className="type-body-copy" >

                                    Мы ищем менеджера контента для работы с нами.

                                </p>
                                <hr className="c-hr c-hr--black" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}


export default (Navigator);