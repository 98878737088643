import React, { Component } from 'react';
import Navigator from '../../components/en/Navigator'
import FullLanding from '../../components/en/Home/FullLanding'

//import Meta from '../Meta';
class App extends Component {
    render() {
        return (
            <div className="App">
                <Navigator/>
                <FullLanding/>
            </div>
        );
    }
}

export default App;
