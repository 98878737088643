import React from 'react';
import FormContact from './FormContact'
import {
    FlageYellowImage,
    FlageYellowWebm, FlageYellowMp4
} from '../../FlowMedia/index'

class HomeBlog extends React.Component {


    render() {

        return (
            <div
                className="_ie-flex-min-height-fix">
                <section className="o-section-full home-blog">
                    <div
                        className="u-width-limiter">
                        <div className="c-section-video-fill-wrapper">
                            <video loop="loop" autoPlay muted poster={FlageYellowImage}>
                                <source
                                    src={FlageYellowWebm} type="video/webm"/>
                                <source
                                    src={FlageYellowMp4} type="video/mp4"/>
                            </video>
                        </div>
                        <div
                            className="home-blog__content-left">

                            <h6 className="type-subheadlines-buttons">
                            </h6>
                            <h2 className="type-hero-headline">
                                <div>Связь
                                    <br/>с нами</div>
                            </h2>
                        </div>
                        <div className="home-blog__content-right js-blog-post">
                            <FormContact/>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default (HomeBlog);
