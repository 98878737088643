import React from 'react';
import {Link} from 'react-router-dom';


class Popchips extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav popchips-landing" >
                        <div className="popchips-chip popchips-chip--a" ><img
                            src="/static/img/case-studies/popchips/chip.png" /></div>
                        <div className="popchips-chip popchips-chip--b" ><img
                            src="/static/img/case-studies/popchips/chip.png" /></div>
                        <div className="popchips-chip popchips-chip--c" ><img
                            src="/static/img/case-studies/popchips/chip.png" /></div>
                        <div className="popchips-packets" ></div>
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper popchips-landing__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >PopChips</h6>
                                    <h2 className="type-bloc-headline" >Pop My Lunch</h2>
                                    <hr className="c-hr c-hr--yellow c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >Making UK lunches
                                        fun.</h3></div>
                            </header>

                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >The light-hearted popped crisp
                                brand wanted to target the lunch market.</h3>
                            <hr className="c-hr c-hr--yellow c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >Our insight:
                                people were lunching more at their desks and weren&#x27;t happy about it. #SadDeskLunch
                                was trending on Instagram and Twitter. Being a brand that thinks all meals should pop -
                                we saw that as an opportunity and created the #PopMyLunch campaign to reverse those sad
                                desk lunches.</p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited" >
                            <figure className="case-study-figure" ><img
                                src="/static/img/case-studies/popchips/popmylunch.jpg" className="u-image-restraints" /></figure>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >We hired Jose Mendez,
                                London-based artist and illustrator to help make the nation’s sad desk lunches pop with
                                the #PopMyLunch campaign.</h3>
                            <hr className="c-hr c-hr--yellow c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >Over two weeks
                                we found some of the saddest lunches and within 20-30 minutes made them pop with a
                                surprise and delight style campaign.</p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" ><img
                            src="/static/img/case-studies/popchips/social.jpg" className="u-image-restraints" /></div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >All digitally popped lunches were
                                followed by a sampling program that put Popchips in the hands of consumers to make their
                                real lunches pop as well!</h3>
                            <hr className="c-hr c-hr--yellow c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" ><img
                            src="/static/img/case-studies/popchips/packets.png" className="u-image-restraints" /></div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >The campaign was executed on a
                                small budget. To maximise it, we engaged all influences, traditional media and social
                                media to give our message reach and credibility.</h3>
                            <hr className="c-hr c-hr--yellow c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <p className="type-body-copy u-text-center" >The Results</p>
                            <h3 className="type-block-sub-headline2" >We reached more than 1 million
                                lunches direct, and another 2 million via PR.</h3>
                            <hr className="c-hr c-hr--yellow c-hr--large" />
                        </div>
                    </section>

                </div>
                <div className=".case-studies-reel" >
                    <Link
                    className="case-studies-reel__item case-studies-reel__item--made-by" to="/en/case-studies/made-by" ><h4 className="type-block-sub-headline" >Made By</h4><h6
                    className="type-subheadlines-buttons" >Campaign</h6></Link>

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--tube-strike"
                    to="/en/case-studies/tube-strike" ><h4 className="type-block-sub-headline" >F*cking Tube Strike</h4>
                    <h6
                        className="type-subheadlines-buttons" >Reactive</h6></Link>

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--jacobs-creek"
                    to="/en/case-studies/jacobs-creek" ><h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                    <h6
                        className="type-subheadlines-buttons" >Website Redesign</h6></Link>

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--more" to="/en/case-studies" ><h4 className="type-block-sub-headline" >Blood, Sweat &amp; Tears</h4><h6
                    className="type-subheadlines-buttons" >See all our work.</h6></Link>

                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (Popchips);
