import React from 'react';
import classNames from 'classnames';

class Navigator extends React.Component {

    constructor() {
        super();
    }


    render() {

        return (
            <div className="page-jobs-landing" >
                <div className="u-width-limiter" >
                    <header className="jobs-landing-heading" >
                        <h1 className="type-subheadlines-buttons" >One step left to greatness</h1>
                        <h2 className="type-hero-headline" >
                            <div >Get your
                                <br/>piece of glory
                            </div>
                        </h2>
                        <hr className="c-hr c-hr--white c-hr--large" />
                    </header>
                    <div className="c-job-list" >
                        <ul className="c-job-list__list" >
                            <li className="c-job-list__item" >
                                <h4 className="type-block-sub-headline" >Midweight Project Manager, MOSCOW</h4>
                                <p className="type-body-copy" >You will be the very essence of organised and multi-tasking will feel like the norm to you. You will have experience on projects from all over the digital spectrum. More so than this, you will have a willingness to learn things outside your wheelhouse and pick up skills quickly.</p>
                                <hr className="c-hr c-hr--black" />
                            </li>
                            <li className="c-job-list__item" >
                                <h4 className="type-block-sub-headline" >Traffic Manager, MOSCOW</h4>
                                <p className="type-body-copy" >We are fast-growing agency working with a number of exciting clients alongside a varied spectrum of project disciplines. This rapid expansion of the agency calls for a highly efficient Traffic Manager to come on board and successfully manage the studio resource at various stages of a project lifestyle.</p>
                                <hr className="c-hr c-hr--black" />
                            </li>
                            <li className="c-job-list__item" >
                                <h4 className="type-block-sub-headline" >Account Manager, MOSCOW</h4>
                                <p className="type-body-copy" >As an Account Manager within one of our Client Management teams, you will be responsible for the day-to-day running of your accounts, including relationship-building with Brand Managers, end-to-end campaign management, and strategic development of accounts.</p>
                                <hr className="c-hr c-hr--black" />
                            </li>
                            <li className="c-job-list__item" >
                                <h4 className="type-block-sub-headline" >Content Manager, ST. PETERSBURG</h4>
                                <p className="type-body-copy" >We are looking for a content manager to work with us.</p>
                                <hr className="c-hr c-hr--black" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}


export default (Navigator);