import React from 'react';

import PrimaryFooterDark from '../../../components/ru/PrimaryFooterDark'
import Popchips from '../../../components/ru/Works/Popchips'
import Navigator from '../../../components/ru/Navigator'
//import Meta from '../../Meta';

class PopchipsC extends React.Component {


    render() {

        return (
            <div className='page-contact'>
                <Navigator/>
                <Popchips/>
                <PrimaryFooterDark/>
            </div>
        )
    }
}

export default (PopchipsC);
