import React from 'react';


class AboutWeAre extends React.Component {


    render() {

        return (
            <div className="_ie-flex-min-height-fix" >
                <section className="o-section-full about-we-are" >
                    <div className="u-width-limiter" >
                        <div className="about-how-we-do-it__content" >
                            <h6 className="type-subheadlines-buttons" >Наши возможности</h6>
                            <h2 className="type-bloc-headline" ><div >Мы создаем:</div></h2></div>
                        <div className="about-deliverables" >
                            <div className="about-deliverables__item" ><img src="/static/img/about/deliverables/book.png"  />
                                <div className="type-body-copy" >
                                    <div >Бренды, Связи & Цифровые стратегии</div>
                                </div>
                            </div>
                            <div className="about-deliverables__item" ><img src="/static/img/about/deliverables/lightbulb.png"  />
                                <div className="type-body-copy" >
                                    <div >Креативные идеи для компаний</div>
                                </div>
                            </div>
                            <div className="about-deliverables__item" ><img src="/static/img/about/deliverables/pencil-and-ruler.png"  />
                                <div className="type-body-copy" >
                                    <div >Дизайн и брендирование</div>
                                </div>
                            </div>
                            <div className="about-deliverables__item" ><img src="/static/img/about/deliverables/terminal.png"  />
                                <div className="type-body-copy" >
                                    <div >Цифровое / веб-производство</div>
                                </div>
                            </div>
                            <div className="about-deliverables__item" ><img src="/static/img/about/deliverables/clapperboard.png"  />
                                <div className="type-body-copy" >
                                    <div >Контентное производство</div>
                                </div>
                            </div>
                            <div className="about-deliverables__item" ><img src="/static/img/about/deliverables/crosshair.png"  />
                                <div className="type-body-copy" >
                                    <div >Создание сообществ</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default (AboutWeAre);
