import React, { Component } from 'react';
import Preloader from '../../../components/en/Preloader'
import Navigator from '../../../components/en/Navigator'
import Up from '../../../components/en/Works/Up'
import FooterWhite from '../../../components/en/FooterWhite'
//import Meta from '../../Meta';

class About extends Component {
    render() {
        return (
            <div className="App">

                <Navigator/>
                <Up/>
                <FooterWhite/>
            </div>
        );
    }
}

export default About;
