import React, { Component } from 'react';
import './static/style/main.css'
import {Switch, Route } from "react-router-dom";
import { routes }  from './config/routes';

export class PublicRoute extends React.Component {
    publicAccess(props) {
        const { component: Component } = this.props;
        return <Component {...props} />
    }

    render() {
        return (
            <Route render={props => this.publicAccess(props)} />
        );
    }
}


class App extends Component {


    render() {
        return (
            <Switch>
                {
                    routes.map((route, i) => (
                        <PublicRoute key={i} {...route} />
                    ))
                }
            </Switch>
        );
    }
}

export default App;
