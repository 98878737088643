import Home from './../containers/ru/Home';
import About from './../containers/ru/About'
import Contact from './../containers/ru/Contact'
import CaseStudies from './../containers/ru/CaseStudies'
import CPJ from './../containers/ru/works/CPJ';
import JacobsCreekC from './../containers/ru/works/JacobsCreekC'
import Beefeater from './../containers/ru/works/Beefeater'
import PopchipsC from './../containers/ru/works/PopchipsC'
import WhatsonC from './../containers/ru/works/WhatsonC'
import Up from './../containers/ru/works/Up'
import RjsmithC from './../containers/ru/works/RjsmithC'
import BrancottEstateC from './../containers/ru/works/BrancottEstateC'
import MadeByC from './../containers/ru/works/MadeByC'
import Primark from './../containers/ru/works/Primark'
import Jobs from './../containers/ru/Jobs'
import Ostopora from './../containers/ru/works/Ostopora'
import Needcars from './../containers/ru/works/Needcars'
import Scopus from './../containers/ru/works/Scopus'

//en
import enHome from './../containers/en/Home';
import enAbout from './../containers/en/About'
import enContact from './../containers/en/Contact'
import enCaseStudies from './../containers/en/CaseStudies'
import enCPJ from './../containers/en/works/CPJ';
import enJacobsCreekC from './../containers/en/works/JacobsCreekC'
import enBeefeater from './../containers/en/works/Beefeater'
import enPopchipsC from './../containers/en/works/PopchipsC'
import enWhatsonC from './../containers/en/works/WhatsonC'
import enUp from './../containers/en/works/Up'
import enRjsmithC from './../containers/en/works/RjsmithC'
import enBrancottEstateC from './../containers/en/works/BrancottEstateC'
import enMadeByC from './../containers/en/works/MadeByC'
import enPrimark from './../containers/en/works/Primark'
import enJobs from './../containers/en/Jobs'
import enOstopora from './../containers/en/works/Ostopora'
import enNeedcars from './../containers/en/works/Needcars'


export const routes = [
	{
		path: "/",
		exact: true,
		component: Home
	},
	{
		path: "/about",
		exact: true,
		component: About
	},
	{
		path: "/contact",
		exact: true,
		component: Contact
	},
	{
		path: "/case-studies",
		exact: true,
		component: CaseStudies
	},
	{
		path: "/case-studies/cpj",
		exact: true,
		component: CPJ
	},
	{
		path: "/case-studies/beefeater",
		exact: true,
		component: Beefeater
	},
	{
		path: "/case-studies/popchips",
		exact: true,
		component: PopchipsC
	},
	{
		path: "/case-studies/jacobs-creek",
		exact: true,
		component: JacobsCreekC
	},
	{
		path: "/case-studies/whatson",
		exact: true,
		component: WhatsonC
	},
	{
		path: "/case-studies/7-up",
		exact: true,
		component: Up
	},
	{
		path: "/case-studies/rjsmith",
		exact: true,
		component: RjsmithC
	},
	{
		path: "/case-studies/brancott-estate",
		exact: true,
		component: BrancottEstateC
	},
	{
		path: "/case-studies/made-by",
		exact: true,
		component: MadeByC
	},
	{
		path: "/case-studies/primark",
		exact: true,
		component: Primark
	},
	{
		path: "/case-studies/ostopora",
		exact: true,
		component: Ostopora
	},
	{
		path: "/case-studies/needcars",
		exact: true,
		component: Needcars
	},
	{
		path: "/case-studies/scopus",
		exact: true,
		component: Scopus
	},
	{
		path: "/jobs",
		exact: true,
		component: Jobs
	},
	{
		path: "/en",
		exact: true,
		component: enHome
	},
	{
		path: "/en/about",
		exact: true,
		component: enAbout
	},
	{
		path: "/en/contact",
		exact: true,
		component: enContact
	},
	{
		path: "/en/jobs",
		exact: true,
		component: enJobs
	},
	{
		path: "/en/case-studies",
		exact: true,
		component: enCaseStudies
	},
	{
		path: "/en/case-studies/cpj",
		exact: true,
		component: enCPJ
	},
	{
		path: "/en/case-studies/beefeater",
		exact: true,
		component: enBeefeater
	},
	{
		path: "/en/case-studies/popchips",
		exact: true,
		component: enPopchipsC
	},
	{
		path: "/en/case-studies/jacobs-creek",
		exact: true,
		component: enJacobsCreekC
	},
	{
		path: "/en/case-studies/whatson",
		exact: true,
		component: enWhatsonC
	},
	{
		path: "/en/case-studies/7-up",
		exact: true,
		component: enUp
	},
	{
		path: "/en/case-studies/rjsmith",
		exact: true,
		component: enRjsmithC
	},
	{
		path: "/en/case-studies/brancott-estate",
		exact: true,
		component: enBrancottEstateC
	},
	{
		path: "/en/case-studies/made-by",
		exact: true,
		component: enMadeByC
	},
	{
		path: "/en/case-studies/primark",
		exact: true,
		component: enPrimark
	},
	{
		path: "/en/case-studies/ostopora",
		exact: true,
		component: enOstopora
	},
	{
		path: "/en/case-studies/needcars",
		exact: true,
		component: enNeedcars
	},
]
