import React from 'react';


class AboutWeAre extends React.Component {


    render() {

        return (
            <div className="_ie-flex-min-height-fix" >
                <section className="o-section-full about-we-are" >
                    <div className="u-width-limiter" >
                        <div className="about-how-we-do-it__content" >
                            <h6 className="type-subheadlines-buttons" >Agency Capabilities</h6>
                            <h2 className="type-bloc-headline" ><div >Everyday we deliver:</div></h2></div>
                        <div className="about-deliverables" >
                            <div className="about-deliverables__item" ><img src="/static/img/about/deliverables/book.png"  />
                                <div className="type-body-copy" >
                                    <div >Brand, Comms & Digital Strategy</div>
                                </div>
                            </div>
                            <div className="about-deliverables__item" ><img src="/static/img/about/deliverables/lightbulb.png"  />
                                <div className="type-body-copy" >
                                    <div >Creative TTL Campaigns</div>
                                </div>
                            </div>
                            <div className="about-deliverables__item" ><img src="/static/img/about/deliverables/pencil-and-ruler.png"  />
                                <div className="type-body-copy" >
                                    <div >Design and branding</div>
                                </div>
                            </div>
                            <div className="about-deliverables__item" ><img src="/static/img/about/deliverables/terminal.png"  />
                                <div className="type-body-copy" >
                                    <div >Digital/web production</div>
                                </div>
                            </div>
                            <div className="about-deliverables__item" ><img src="/static/img/about/deliverables/clapperboard.png"  />
                                <div className="type-body-copy" >
                                    <div >Content Production</div>
                                </div>
                            </div>
                            <div className="about-deliverables__item" ><img src="/static/img/about/deliverables/crosshair.png"  />
                                <div className="type-body-copy" >
                                    <div >Community Engagement</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default (AboutWeAre);
