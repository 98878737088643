import React from 'react';


class AboutWhatWeDo extends React.Component {


    render() {

        return (
            <div className="_ie-flex-min-height-fix" >
                <section className="o-section-full about-what-we-do" >
                    <div className="u-width-limiter" >
                        <div className="about-what-we-do__content" >
                            <h6 className="type-subheadlines-buttons" >Что мы делаем?</h6>
                            <h2 className="type-bloc-headline" > <div>Мы<br/>вдыхаем жизнь<br/>в идеи.<br/></div></h2>
                            <h6 className="type-block-sub-headline2" ><div >
                                Иногда это случается. По какой-то причине известные бренды теряют свой путь.
                                Методы развития бреда устаревают, а рынок движется вперед. В результате бренд забывается.
                                <br/><br/>Назовите нас ностальгическими или сентиментальными, или и то, и другое, но мы волуемся за каждый бренд, с которым работаем.
                                Мы сотрудничаем с каждым нашим клиентном индивидуально и вкладываем частичку себя в каждый проект. Поэтому все наши клиенты, остаются с нами на многие года сотрудничества.
                                <br/><br/>Это то, что мы умеем, это то, что мы знаем, это то, что мы любим - мы делаем бренды узнаваемыми.</div></h6>
                            <hr className="c-hr c-hr--black c-hr--large"  />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default (AboutWhatWeDo);
