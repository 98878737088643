import React from 'react';
import {Link} from 'react-router-dom';

class PageCaseStudyLandingHero extends React.Component {


    render() {

        return (
            <section className="o-section-full page-case-study-landing__hero" >
                <div className="u-width-limiter" >
                    <div className="page-case-study-landing__hero__content" >
                        <h6 className="type-subheadlines-buttons" >Наш последний кейс</h6>
                        <h2 className="type-hero-headline" >
                            <div >Возвращение
                                <br/>Beefeater Gin (бренда)
                                <br/>к успеху
                            </div>
                        </h2>
                        <hr className="c-hr c-hr--white c-hr--large"  />
                        <Link to="/case-studies/beefeater" >
                            <h6 className="type-subheadlines-buttons" >Нажмите чтобы посмотреть</h6>
                        </Link>
                        <hr className="c-hr c-hr--white"  />
                    </div>
                </div>
            </section>
        )
    }
}

export default (PageCaseStudyLandingHero);
