import React from 'react';
import {Link} from 'react-router-dom';

class PageCaseStudyLandingHero extends React.Component {


    render() {

        return (
            <section className="case-study-landing-half-blocks-wrapper">
                <div className="c-work-list">
                    <ul className="c-work-list__list">
                        <li className="c-work-list__item c-work-list__item--needcars c-work-list__item--featured" >
                            <Link className="c-work-list__item-link" to="/en/case-studies/needcars">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Need-Cars</h6>
                                                        <h3 className="type-bloc-headline fw300">Need-Cars selection of cars</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--ostopora">
                            <Link className="c-work-list__item-link" to="/en/case-studies/ostopora">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Точка опоры</h6>
                                                        <h3 className="type-bloc-headline fw300">Clinic of Osteopathy Точка опоры</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--primark">
                            <Link className="c-work-list__item-link" to="/en/case-studies/primark">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Primark</h6>
                                                        <h3 className="type-bloc-headline">Putting live video at the core of the Christmas campaign</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        {/*<li className="c-work-list__item c-work-list__item--7-up">*/}
                            {/*<Link className="c-work-list__item-link" to="/en/case-studies/7-up">*/}
                                {/*<span className="c-work-list__item-bg"></span>*/}
                                {/*<span className="c-work-list__item-wrapper">*/}
                                                    {/*<span className="c-work-list__item-inner">*/}
                                                        {/*<h6 className="type-subheadlines-buttons">7-up</h6>*/}
                                                        {/*<h3 className="type-bloc-headline">7-up verses the heat</h3>*/}
                                                        {/*<hr className="c-hr c-hr--white c-hr--large"/>*/}
                                                    {/*</span>*/}
                                                {/*</span>*/}
                            {/*</Link>*/}
                        {/*</li>*/}
                        {/*<li className="c-work-list__item c-work-list__item--tube-strike">*/}
                            {/*<Link className="c-work-list__item-link" to="/en/case-studies/tube-strike">*/}
                                {/*<span className="c-work-list__item-bg"></span>*/}
                                {/*<span className="c-work-list__item-wrapper">*/}
                                                    {/*<span className="c-work-list__item-inner">*/}
                                                        {/*<h6 className="type-subheadlines-buttons">Internal Project</h6>*/}
                                                        {/*<h3 className="type-bloc-headline">The F&cking Tube Strike</h3>*/}
                                                        {/*<hr className="c-hr c-hr--white c-hr--large"/>*/}
                                                    {/*</span>*/}
                                                {/*</span>*/}
                            {/*</Link>*/}
                        {/*</li>*/}
                        <li className="c-work-list__item c-work-list__item--jacobs-creek">
                            <Link className="c-work-list__item-link" to="/en/case-studies/jacobs-creek">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Jacob&#x27;s Creek</h6>
                                                        <h3 className="type-bloc-headline">Relaunching Jacob's Creek Online</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--whatson">
                            <Link className="c-work-list__item-link"to="/en/case-studies/whatson">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Flicks.co.nz</h6>
                                                        <h3 className="type-bloc-headline">Facebook Messenger Chat Bot</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--cpj">
                            <Link className="c-work-list__item-link" to="/en/case-studies/cpj">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">CPJ</h6>
                                                        <h3 className="type-bloc-headline">Launching a new online
                                                            <br/>juice company
                                                        </h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--brancott-estate">
                            <Link className="c-work-list__item-link" to="/en/case-studies/brancott-estate">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Brancott Estate</h6>
                                                        <h3 className="type-bloc-headline">Brancott Estate Pioneers</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/></span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--popchips">
                            <Link className="c-work-list__item-link" to="/en/case-studies/popchips">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">PopChips</h6>
                                                        <h3 className="type-bloc-headline">Pop My Lunch</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--made-by">
                            <Link className="c-work-list__item-link" to="/en/case-studies/made-by">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Jacob&#x27;s Creek</h6>
                                                        <h3 className="type-bloc-headline">Made By with Novak Djokovic</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--beefeater">
                            <Link className="c-work-list__item-link" to="/en/case-studies/beefeater">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Beefeater</h6>
                                                        <h3 className="type-bloc-headline">Returning
                                                            <br/>Beefeater Gin to Fame and Glory
                                                        </h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--rjsmith">
                            <Link className="c-work-list__item-link" to="/en/case-studies/rjsmith">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">RJ Smith</h6>
                                                        <h3 className="type-bloc-headline">Building of Tradition</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default (PageCaseStudyLandingHero);
