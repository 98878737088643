import React from 'react';
import {Link} from 'react-router-dom';



class FullLanding extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav cpj" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper cpj__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >CPJ: COLD PRESSED JUICE</h6>
                                    <h2 className="type-bloc-headline" >
                                        <div >Launching a new online juice company.</div>
                                    </h2>
                                    <hr className="c-hr c-hr--cpj" />
                                </div>
                            </header>

                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >We started with a name and ended with a complete brand world, including packaging, website and an advertising launch plan.</h3>
                            <hr className="c-hr c-hr--cpj c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >The ‘Fort’ monogram was inspired by the Saint John’s Arms, a symbol of defence and strength. But you probably know it better as the common digital icon for ‘command’ on a Mac.</p>
                            <p className="type-body-copy case-study-limited-paragraph" >Starting from there, we developed the brand from the ground up, creating packaging, a tone of voice system and a mobile-first website with a powerful ordering system. We wanted flexibility and ease. We wanted to deliver a shortcut to health.</p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited u-no-padding-below-desktop" >
                            <div className="c-mediagrid" >
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/monogram.png" alt="CPJ monogram" className="u-image-restraints" />
                                </div>
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/logo.png" alt="CPJ logo" className="u-image-restraints" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-no-padding-below-desktop" >
                            <div className="c-mediagrid" >
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/bottle-shift.jpg" id="cpj-cs-branding-mg-0" className="u-image-restraints" />
                                </div>
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/bottle-refresh.jpg" id="cpj-cs-branding-mg-1" className="u-image-restraints" />
                                </div>
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/bottle-eject.jpg" id="cpj-cs-branding-mg-2" className="u-image-restraints" />
                                </div>
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/business-cards.jpg" id="cpj-cs-branding-mg-3" className="u-image-restraints" />
                                </div>
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/ingredients.jpg" id="cpj-cs-branding-mg-4" className="u-image-restraints" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Website, eshop and brand experience.</h3>
                            <hr className="c-hr c-hr--cpj c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >The CPJ website was launched as the centre of the brand world and as its shop front. We took care of the design, front end development and user journeys, our friends at PureBlue plugged our work into Magento to enable the shop functionality.</p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <img src="/static/img/case-studies/cpj/devices.jpg" alt="CPJ website preview in several devices." className="u-image-full-width case-study-reveal-large-image u-tablet-and-up-only" />
                        <img src="/static/img/case-studies/cpj/devices-mobile.jpg" alt="CPJ website preview in several devices." className="u-image-full-width case-study-reveal-large-image u-mobile-only" />
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >To launch we created the CPJ Juice clinic, an interactive journey that allows consumers to find the right juice for their body, lifestyle and fitness regime.</h3>
                            <hr className="c-hr c-hr--cpj c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited u-no-padding-below-desktop" >
                            <figure className="case-study-figure" >
                                <div >
                                    <video controls="" loop="loop" className="u-image-restraints js-controls-video" autoPlay>
                                        <source src="/static/vids/case-studies/cpj.webm" type="video/webm" />
                                        <source src="/static/vids/case-studies/cpj.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>

                </div>
                <div className=".case-studies-reel" >
                    <Link className="case-studies-reel__item case-studies-reel__item--tube-strike" to="/en/case-studies/tube-strike" >
                        <h4 className="type-block-sub-headline" >F*cking Tube Strike</h4>
                        <h6 className="type-subheadlines-buttons" >Reactive</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/en/case-studies/made-by" >
                        <h4 className="type-block-sub-headline" >Made By</h4>
                        <h6 className="type-subheadlines-buttons" >Campaign</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/en/case-studies/rjsmith" >
                        <h4 className="type-block-sub-headline" >RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons" >Branding / Website</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/en/case-studies" >
                        <h4 className="type-block-sub-headline" >Blood, Sweat &amp; Tears</h4>
                        <h6 className="type-subheadlines-buttons" >See all our work.</h6>
                    </Link>
                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (FullLanding);
