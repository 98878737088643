import React from 'react';
import {Link} from 'react-router-dom';


class Whatson extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav whatson" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper whatson__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >FLICKS.CO.NZ</h6>
                                    <h2 className="type-bloc-headline" >
                                        <div >Создание Facebook Чат Бот
                                        </div>
                                    </h2>
                                    <hr className="c-hr c-hr--whatson c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >
                                        Запуск Чат Бота в Facebook Messenger по мотивам известного мультика
                                    </h3></div>
                            </header>
                        </div>
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2 whatson" >
                                flicks.co.nz хотел принять участие в революции роботов, поэтому мы создали Bot Messenger Facebook, который мог бы точно сказать, что происходит, - мы назвали его Whatson - у нас получилось?
                            </h3>
                            <hr className="c-hr c-hr--whatson c-hr--large" />
                        </div>
                        <div className="whatson-o-animations-container one js-ticket-container" ><img
                            src="/static/img/case-studies/whatson/robot-arm.png" className="u-image-restraints" /><img src="/static/img/case-studies/whatson/robot-ticket-2.png"
                                                    className="u-image-restraints js-anim-ticket-2" /><img
                            src="/static/img/case-studies/whatson/robot-ticket-1.png"
                            className="u-image-restraints js-anim-ticket-1" /><img
                            src="/static/img/case-studies/whatson/robot-finger.png" className="u-image-restraints" /></div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >В рамках этого проекта,
                                также мы разработали собственную платформу Bot Messenger, которая позволяет нам наилучшим образом использовать
                                платформу Facebook.</h3>
                            <hr className="c-hr c-hr--whatson c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std whatson__devices" ><img
                        src="/static/img/case-studies/whatson/devices.jpg" alt="Whatson in several devices"
                        className="u-image-full-width case-study-reveal-large-image u-tablet-and-up-only" /><img src="/static/img/case-studies/whatson/devices-mobile.jpg"
                                                alt="Whatson in several devices"
                                                className="u-image-full-width case-study-reveal-large-image u-mobile-only" />
                        <figure className="case-study-figure whatson__devices-video" >
                            <div >
                                <video autoPlay muted loop className="u-image-restraints js-controls-video" >
                                    <source src="/static/vids/case-studies/whatson.webm" type="video/webm" />
                                    <source src="/static/vids/case-studies/whatson.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </figure>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <div className="whatson-o-animations-container two js-anim-robot-container" ><img src="/static/img/case-studies/whatson/robot-floor.png"
                                                        className="u-image-restraints js-anim-robot-floor" /><img
                                src="/static/img/case-studies/whatson/robot-messenger.png"
                                className="u-image-restraints js-anim-messenger" /><img
                                src="/static/img/case-studies/whatson/bender.png"
                                className="u-image-restraints js-anim-anim-one-robot" /></div>
                            <Link className="whatson__chat-btn" to="/contact" >
                                Давайте поговорим о создании бота для Вашего бренда
                            </Link></div>
                    </section>
                </div>
                <div className=".case-studies-reel" >
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek" to="/case-studies/jacobs-creek" >
                        <h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons" >Обновление сайта Jacob's Creek</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/case-studies/made-by" >
                        <h4 className="type-block-sub-headline" >Made By</h4>
                        <h6 className="type-subheadlines-buttons" >Создание истории бренда «Made By»</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/case-studies/rjsmith" >
                        <h4 className="type-block-sub-headline" >RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons" >Создание бренда</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/case-studies" >
                        <h4 className="type-block-sub-headline" >Кровь, Пот &amp; Слезы</h4>
                        <h6 className="type-subheadlines-buttons" >Посмотреть все наши работы.</h6>
                    </Link>
                </div>
            </div>
        )
    }
}

export default (Whatson);
