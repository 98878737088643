import React from 'react';

import PrimaryFooterDark from '../../../components/ru/PrimaryFooterDark'
import JacobsCreek from '../../../components/ru/Works/JacobsCreek'
import Navigator from '../../../components/ru/Navigator'

//import Meta from '../../Meta';

class JacobsCreekC extends React.Component {


    render() {

        return (
            <div className='page-contact'>
                <Navigator/>
                <JacobsCreek/>
                <PrimaryFooterDark/>
            </div>
        )
    }
}

export default (JacobsCreekC);
