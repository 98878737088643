import React from 'react';
import {Link} from 'react-router-dom';


class FullLanding extends React.Component {


    render() {

        return (
            <div className="page-case-study">
                <div className="_ie-flex-min-height-fix">
                    <section className="o-section-full o-section--below-nav beefeater">
                        <div className="u-width-limiter">
                            <header className="case-study-heading-wrapper beefeater__heading-wrapper">
                                <div className="case-study-heading">
                                    <h6 className="type-subheadlines-buttons">Beefeater Gin: Глобальная
                                        Перезагрузка</h6>
                                    <h2 className="type-bloc-headline">
                                        <div>Возвращение Beefeater Gin (бренда) к успеху</div>
                                    </h2>
                                    <hr className="c-hr c-hr--white c-hr--large"/>
                                </div>
                            </header>

                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper">
                    <div className="case-study-sections-wrapper--beefeater-red">
                        <section className="o-section-std no-padding-top">
                            <div className="u-width-limiter u-width-limiter--low-limited">
                                <h3 className="type-block-sub-headline2">
                                    Прямо сейчас, происходит глобальный бум Джина. Этот напиток находится на пике популярности,
                                    но один из самых исторически знаковых брендов, Beefeater, потерял свою известность.
                                </h3>
                                <hr className="c-hr c-hr--white"/>
                                <p className="type-body-copy case-study-limited-paragraph">
                                    С появлением большого количества разнообразных марок джина, потребители почувствовали, что Beefeater стал
                                    "вчерашним" брендом. Наша задача заключалась в том, чтобы найти новую аудиторию,
                                    согласовать ее с заказчиком и возобновить бренд во всем мире.
                                </p>
                                <h6 className="type-subheadlines-buttons">Beefeater Gin: Глобальная перезагрузка</h6>
                                <p className="type-body-copy case-study-limited-paragraph">
                                    Мы не стали рассказывать фантастические истории о викторианских временах или
                                    магических сюжетах. Мы сосредоточились на новом поколении любителей джина, молодых, городских и реальных ребятах.
                                </p>
                            </div>
                        </section>
                        <section className="o-section-std">
                            <div className="u-width-limiter u-width-limiter--high-limited u-no-padding-below-desktop">
                                <figure className="case-study-figure">
                                    <div>
                                        <video loop="loop" autoPlay muted
                                               className="u-image-restraints js-controls-video">
                                            <source src="/static/vids/case-studies/beefeater.mp4" type="video/mp4"/>
                                        </video>
                                    </div>
                                </figure>
                            </div>
                        </section>
                    </div>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited">
                            <h3 className="type-block-sub-headline2">Новый мир брендов</h3>
                            <hr className="c-hr c-hr--black"/>
                            <p className="type-body-copy">
                                Мы полностью переработали бренд-мир Beefeater и создали городской и энергичный язык
                                дизайна, который воплотили в жизнь.
                            </p>
                            <img src="/static/img/case-studies/beefeater/flashing-lines.gif"
                                 className="u-image-restraints"/>
                            <div className="o-image-grid-container">
                                <div className="c-image-grid-row">
                                    <div className="two-col">
                                        <img src="/static/img/case-studies/beefeater/beefeater-bags.jpg"
                                             className="u-image-restraints"/>
                                        <img src="/static/img/case-studies/beefeater/beefeater-models.jpg"
                                             className="u-image-restraints"/>
                                    </div>
                                    <div className="large">
                                        <img src="/static/img/case-studies/beefeater/beefeater-board.jpg"
                                             className="u-image-restraints"/>
                                    </div>
                                </div>
                                <div className="c-image-grid-row image-grid--reversed">
                                    <div className="two-col">
                                        <div>
                                            <video autoplay loop muted className="u-image-restraints">
                                                <source src="/static/vids/case-studies/beefeater-2.mp4"
                                                        type="video/mp4"/>
                                            </video>
                                        </div>
                                        <img src="/static/img/case-studies/beefeater/iphone-cases.jpg"
                                             className="u-image-restraints"/>
                                    </div>
                                    <div className="large">
                                        <img src="/static/img/case-studies/beefeater/brick-wall.jpg"
                                             className="u-image-restraints"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="case-study-sections-wrapper--beefeater-red" >
                        <section className="o-section-std" >
                            <div className="u-width-limiter u-width-limiter--low-limited" >
                                <h3 className="type-block-sub-headline2" >
                                    Мы искали партнера, который помог бы нам творчески перевести то, что создаст новый внешний вид.
                                    С Ideanix мы нашли стиль, который на 100% соответствует нашему видению.

                                </h3>
                                <hr className="c-hr c-hr--white" />
                                <p className="type-subheadlines-buttons quote" >- Эрик Самплес, Бренд Директор Beefeater Gin.</p>
                            </div>
                        </section>
                    </div>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Поговорим о работах на улицах</h3>
                            <hr className="c-hr c-hr--black" />
                            <p className="type-body-copy" >
                                На основных рынках (Великобритания, Испания, Бельгия и др.) мы начали рекламную кампанию.
                            </p>
                            <div className="o-image-grid-container" >
                                <div className="c-image-grid-row" >
                                    <div className="grid-item" >
                                        <img src="/static//img/case-studies/beefeater/section-4-grid-1.jpg"
                                             className="u-image-restraints" />
                                    </div>
                                    <div className="grid-item" >
                                        <img src="/static/img/case-studies/beefeater/section-4-grid-2.jpg"
                                             className="u-image-restraints" />
                                    </div>
                                </div>
                                <div className="c-image-grid-row" >
                                    <div className="grid-item" >
                                        <img src="/static/img/case-studies/beefeater/section-4-grid-3.jpg"
                                             className="u-image-restraints" />
                                    </div>
                                    <div className="grid-item" >
                                        <img src="/static/img/case-studies/beefeater/section-4-grid-4.jpg"
                                             className="u-image-restraints" />
                                    </div>
                                </div>
                                <div >
                                    <video autoPlay loop muted className="u-image-restraints" >
                                        <source src="/static/vids/case-studies/beefeater-3.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="case-study-sections-wrapper--beefeater-red" >
                        <section className="o-section-std" >
                            <div className="u-width-limiter u-width-limiter--low-limited" >
                                <h3 className="type-block-sub-headline2" >
                                    С нашей рекламной кампанией мы хотели чувствовать, что мы являемся частью города.
                                </h3>
                                <hr className="c-hr c-hr--white" />
                                <p className="type-subheadlines-buttons quote" >- Адам Боита, Директор Бренда, Pernod Ricard </p>
                            </div>
                        </section>
                    </div>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Создание новой возможности</h3>
                            <hr className="c-hr c-hr--black" />
                            <p className="type-body-copy" >
                               Мы обратились к своей аудитории "Лично", с новым привлекательным дизайном
                            </p>
                            <div className="o-image-grid-container" >
                                <div className="c-image-grid-row" >
                                    <div className="grid-item" >
                                        <img src="/static/img/case-studies/beefeater/section-6-grid-1.jpg"
                                             className="u-image-restraints" />
                                    </div>
                                </div>
                                <div className="c-image-grid-row" >
                                    <div className="grid-item" >
                                        <div >
                                            <video autoPlay loop muted className="u-image-restraints" >
                                                <source src="/static/vids/case-studies/beefeater-4.mp4" type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                    <div className="grid-item grid-80" ></div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <div className="case-studies-reel" >
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith"
                          to="/case-studies/rjsmith" >
                        <h4 className="type-block-sub-headline" >RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons" >Создание бренда</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--tube-strike"
                          to="/case-studies/tube-strike" >
                        <h4 className="type-block-sub-headline" >F*cking Tube Strike</h4>
                        <h6 className="type-subheadlines-buttons" >Перезагрузка</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek"
                          to="/case-studies/jacobs-creek" >
                        <h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons" >Новый дизайн</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/case-studies" >
                        <h4 className="type-block-sub-headline" >Кровь, Пот &amp; Слезы</h4>
                        <h6 className="type-subheadlines-buttons" >Посмотреть все остальные работы</h6>
                    </Link>
                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (FullLanding);
