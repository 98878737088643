import React from 'react';
import {Link} from 'react-router-dom';

class FullLanding extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav seven-up" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper brancott-estate__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >Need-Cars</h6>
                                    <h2 className="type-bloc-headline" >
                                        <div >Help in choosing a car with a run</div>
                                    </h2>
                                    <hr className="c-hr c-hr--seven-up c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >Creating a company website</h3>
                                </div>
                            </header>
                            <div className="sub-header" >
                                <h3 className="type-block-sub-headline2" >
                                    Everyone faced the choice of a car anyway. The company Need-Cars has been engaged in the selection of cars for many years and has accumulated a vast amount of expert work experience.                                </h3>
                                <hr className="c-hr c-hr--seven-up c-hr--large" />
                            </div>
                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >The tasks set in the project before us.</h3>
                            <hr className="c-hr c-hr--seven-up c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >
                                We needed to perform work on creating the design, structure and engine of the site. The main requirements of the customer were: simplicity of the unit, functional feedback forms and applications for the service. The aim of the project is to create a site on which it is possible to get acquainted with the work of the company and make the order for obtaining the service as simple as possible.                            </p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited u-no-padding-below-desktop" >
                            <figure className="case-study-figure" >
                                <div >
                                    <video autoPlay muted loop className="u-image-restraints js-controls-video" >
                                        <source src="/static/vids/case-studies/needcars/needcars.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                As a result of our work, we created a website that meets all the requirements. We have created mailings for the company's customers and inside the company too. Thanks to the well-coordinated teamwork of the customer and our specialists, we managed to create a worthy product in a rather limited budget. I would like to thank the leaders of the company for the healthy perfectionism close to us.                            </h3>
                            <hr className="c-hr c-hr--seven-up c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <img src="/static/img/case-studies/needcars/preview.jpg" alt="7 Up website preview in several devices." className="u-image-full-width case-study-reveal-large-image" />
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                Even after the completion of the task and the delivery of the project, we remain in touch with our customers and, at their request, finalize the products as needed.
                            </h3>
                            <hr className="c-hr c-hr--seven-up c-hr--large" />
                        </div>
                    </section>
                </div>
                <div className=".case-studies-reel" >
                    <Link
                        className="case-studies-reel__item case-studies-reel__item--made-by" to="/en/case-studies/made-by" ><h4 className="type-block-sub-headline" >Made By</h4><h6
                        className="type-subheadlines-buttons" >Campaign</h6></Link>

                    <Link
                        className="case-studies-reel__item case-studies-reel__item--tube-strike"
                        to="/en/case-studies/tube-strike" ><h4 className="type-block-sub-headline" >F*cking Tube Strike</h4>
                        <h6
                            className="type-subheadlines-buttons" >Reactive</h6></Link>

                    <Link
                        className="case-studies-reel__item case-studies-reel__item--jacobs-creek"
                        to="/en/case-studies/jacobs-creek" ><h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                        <h6
                            className="type-subheadlines-buttons" >Website Redesign</h6></Link>

                    <Link
                        className="case-studies-reel__item case-studies-reel__item--more" to="/en/case-studies" ><h4 className="type-block-sub-headline" >Blood, Sweat &amp; Tears</h4><h6
                        className="type-subheadlines-buttons" >See all our work.</h6></Link>

                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (FullLanding);
