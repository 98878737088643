import CrowImage from '../../static/vids/crown.jpg'

import MalletImage from '../../static/vids/mallet.jpg'

import FlageYellowImage from '../../static/vids/flag-yellow.jpg'

export {
    CrowImage,  MalletImage, FlageYellowImage
}

export const FlageYellowWebm = 'https://393518.selcdn.ru/ideanix/flag-yellow.webm'
export const FlageYellowMp4 = 'https://393518.selcdn.ru/ideanix/flag-yellow.mp4'
export const MallerOptimMp4 = 'https://393518.selcdn.ru/ideanix/mallet_optim.mp4'
export const MallerOptimWebm = 'https://393518.selcdn.ru/ideanix/mallet_optim.webm'
export const CrownOptimWebm = 'https://393518.selcdn.ru/ideanix/crown_optim.webm'
export const CrownOptimMp4 = 'https://393518.selcdn.ru/ideanix/crown_optim.mp4'