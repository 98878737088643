import React from 'react';
import {Link} from 'react-router-dom';



class Whatson extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav whatson" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper whatson__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >FLICKS.CO.NZ</h6>
                                    <h2 className="type-bloc-headline" >
                                        <div >Facebook Messenger Chat Bot
                                        </div>
                                    </h2>
                                    <hr className="c-hr c-hr--whatson c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >Launching a know-all
                                        movie Chat Bot On Facebook Messenger</h3></div>
                            </header>
                        </div>
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2 whatson" >flicks.co.nz wanted to
                                take part in the ‘bot’ revolution - so we created and built a Facebook Messenger Bot
                                that could tell you exactly what’s on - we called him Whatson - get it?</h3>
                            <hr className="c-hr c-hr--whatson c-hr--large" />
                        </div>
                        <div className="whatson-o-animations-container one js-ticket-container" ><img
                            src="/static/img/case-studies/whatson/robot-arm.png" className="u-image-restraints" /><img src="/static/img/case-studies/whatson/robot-ticket-2.png"
                                                    className="u-image-restraints js-anim-ticket-2" /><img
                            src="/static/img/case-studies/whatson/robot-ticket-1.png"
                            className="u-image-restraints js-anim-ticket-1" /><img
                            src="/static/img/case-studies/whatson/robot-finger.png" className="u-image-restraints" /></div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >As part of this project, impero
                                also developed our own proprietary Messenger Bot Framework that allows us to best use
                                the Facebook platform.</h3>
                            <hr className="c-hr c-hr--whatson c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std whatson__devices" ><img
                        src="/static/img/case-studies/whatson/devices.jpg" alt="Whatson in several devices"
                        className="u-image-full-width case-study-reveal-large-image u-tablet-and-up-only" /><img src="/static/img/case-studies/whatson/devices-mobile.jpg"
                                                alt="Whatson in several devices"
                                                className="u-image-full-width case-study-reveal-large-image u-mobile-only" />
                        <figure className="case-study-figure whatson__devices-video" >
                            <div >
                                <video loop autoPlay muted className="u-image-restraints js-controls-video" >
                                    <source src="/static/vids/case-studies/whatson.webm" type="video/webm" />
                                    <source src="/static/vids/case-studies/whatson.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </figure>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <div className="whatson-o-animations-container two js-anim-robot-container" ><img src="/static/img/case-studies/whatson/robot-floor.png"
                                                        className="u-image-restraints js-anim-robot-floor" /><img
                                src="/static/img/case-studies/whatson/robot-messenger.png"
                                className="u-image-restraints js-anim-messenger" /><img
                                src="/static/img/case-studies/whatson/bender.png"
                                className="u-image-restraints js-anim-anim-one-robot" /></div>
                            <Link className="whatson__chat-btn" to="/en/contact" >Talk to us about
                                developing a Chat Bot for your brand</Link></div>
                    </section>
                </div>
                <div className=".case-studies-reel" >
                    <Link
                    className="case-studies-reel__item case-studies-reel__item--tube-strike"
                    to="/en/case-studies/tube-strike" ><h4 className="type-block-sub-headline" >F*cking Tube Strike</h4><h6
                    className="type-subheadlines-buttons" >Reactive</h6></Link>

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--popchips" to="/en/case-studies/popchips" ><h4 className="type-block-sub-headline" >Pop Chips</h4><h6
                    className="type-subheadlines-buttons" >Social Media</h6></Link>

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--made-by" to="/en/case-studies/made-by" ><h4 className="type-block-sub-headline" >Made By</h4><h6
                    className="type-subheadlines-buttons" >Campaign</h6></Link>

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--more" to="/en/case-studies" ><h4
                    className="type-block-sub-headline" >Blood, Sweat &amp; Tears</h4><h6
                    className="type-subheadlines-buttons" >See all our work.</h6></Link>

                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (Whatson);
