import React from 'react';
import {Link} from 'react-router-dom';



class MadeBy extends React.Component {


    render() {

        return (
            <div className="page-case-study">
                <div className="_ie-flex-min-height-fix">
                    <section className="o-section-full o-section--below-nav made-by-landing">
                        <div className="u-width-limiter">
                            <header className="case-study-heading-wrapper made-by-landing__heading-wrapper">
                                <div className="case-study-heading">
                                    <h6 className="type-subheadlines-buttons">Jacob&#x27;s Creek</h6>
                                    <h2 className="type-bloc-headline">
                                        <div>Создание<br/>истории бренда<br/>«Made By»</div>
                                    </h2>
                                    <hr className="c-hr c-hr--red c-hr--large"/>
                                    <h3 className="type-block-sub-headline2">

                                    </h3></div>
                            </header>
                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper">
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited">
                            <h3 className="type-block-sub-headline2">
                                Совместная работа с нашими друзьями из C + P и OGB, мы начали разрабатывать глобальный проект основанный на создание истории бренда «Made By» от Джейкоба Крик. В качестве лица бренда Новак Джокович.
                            </h3>
                            <hr className="c-hr c-hr--red c-hr--large"/>
                            <p className="type-body-copy case-study-limited-paragraph">
                                Задача кампании состояла в создании трех фильмов снятых C + P;
                                Наша зона ответсвенности была в создании рекламных кампаний, целью которых было впривлечение внимания к бренду.
                                Так же мы разработали сайт компании с внедренной  CRM системой.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--high-limited">
                            <a href="https://www.youtube.com/watch?v=VasWcH5vCTs" target="_blank">
                                <figure className="case-study-figure">
                                    <div className="made-by-loop-video">
                                        <video loop="loop" autoPlay className="u-image-restraints">
                                            <source src="../../../static/vids/case-studies/made-by-loop.webm" type="video/webm"/>
                                            <source src="../../../static/vids/case-studies/made-by-loop.mp4" type="video/mp4"/>
                                        </video>
                                        <img src="../../../static/img/case-studies/made-by/made-by-loop-mob.jpg"
                                             className="u-image-restraints"/></div>
                                </figure>
                            </a>
                        </div>
                    </section>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited">
                            <h3 className="type-block-sub-headline2">
                                За концепцию проекта мы взяли предложение Джейкоба Крик звучащее как "Made By Australia",
                                и Новак Джоковича «Made by Determination»
                            </h3>
                            <hr className="c-hr c-hr--red c-hr--large"/>
                        </div>
                    </section>
                    <section className="o-section-std"><img
                        src="../../../static/img/case-studies/made-by/devices.png"
                        alt="Made By website preview in several devices."
                        className="u-image-full-width case-study-reveal-large-image"/></section>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--high-limited">
                            <figure className="case-study-figure">
                                <div>
                                    <video loop="loop" autoPlay controls=""
                                           poster="../../../static/vids/case-studies/made-by.jpg"
                                           className="u-image-restraints js-controls-video">
                                        <source src="/static/vids/case-studies/made-by.webm" type="video/webm"/>
                                        <source src="/static/vids/case-studies/made-by.mp4" type="video/mp4"/>
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited">
                            <h3 className="type-block-sub-headline2">
                                Мы хотели бы рассказать вам все результаты,
                                потому что они были, действительно, потрясающими, но они конфиденциальны.
                                Однако мы можем сказать вам чего мы достигли:
                            </h3>
                            <hr className="c-hr c-hr--red c-hr--large"/>
                        </div>
                    </section>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited">
                            <p className="type-body-copy u-text-center">Результаты</p>
                            <div className="case-study-impressions">
                                <div className="case-study-impressions__group">
                                    <h2 className="type-bloc-headline">210%</h2>
                                    <p className="type-body-copy">Достижение KPI</p>
                                </div>
                                <div className="case-study-impressions__group">
                                    <h2 className="type-bloc-headline">220%</h2>
                                    <p className="type-body-copy">Ожидаемое ко-во просмотров KPI</p>
                                </div>
                                <div className="case-study-impressions__group">
                                    <h2 className="type-bloc-headline">364%</h2>
                                    <p className="type-body-copy">Увеличение трафика</p>
                                </div>
                                <div className="case-study-impressions__group">
                                    <h2 className="type-bloc-headline">170%</h2>
                                    <p className="type-body-copy">Увеличение взаимодействий</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className=".case-studies-reel">
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek" to="/case-studies/jacobs-creek">
                        <h4 className="type-block-sub-headline">Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons">Обновление сайта Jacob's Creek</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/case-studies/made-by">
                        <h4 className="type-block-sub-headline">Made By</h4>
                        <h6 className="type-subheadlines-buttons">Создание истории бренда «Made By»</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/case-studies/rjsmith">
                        <h4 className="type-block-sub-headline">RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons">Создание бренда</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/case-studies">
                        <h4 className="type-block-sub-headline">Кровь, Пот &amp; Слезы</h4>
                        <h6 className="type-subheadlines-buttons">Посмотреть все наши работы.</h6>
                    </Link>
                </div>
                <div className="u-footer-spacer"></div>
            </div>
        )
    }
}

export default (MadeBy);
