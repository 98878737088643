import React from 'react';


class AboutWhatWeDo extends React.Component {


    render() {

        return (
            <div className="_ie-flex-min-height-fix" >
                <section className="o-section-full about-what-we-do" >
                    <div className="u-width-limiter" >
                        <div className="about-what-we-do__content" >
                            <h6 className="type-subheadlines-buttons" >What we do</h6>
                            <h2 className="type-bloc-headline" >
                                <div >
                                    We make<br/>tired brands<br/>famous again.</div></h2>
                            <h6 className="type-block-sub-headline2" ><div >Sometimes it happens. For whatever reason famous brands lose their way. Messages get old and markets move on. People stop caring, then they stop listening. Brands just get tired.<br/><br/>Call us nostalgic, or sentimental, or both. These are the types of brands that we get most excited about. These are the brands that our proprietary strategies and methods work best for. These are the brands that we make famous again.<br/><br/>It's what we're good at, it's what we know, it's what we love - we make tired brands famous again.</div></h6>
                            <hr className="c-hr c-hr--black c-hr--large"  />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default (AboutWhatWeDo);
