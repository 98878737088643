import React from 'react';


class CaseStudyLandingDivide extends React.Component {


    render() {

        return (
            <div className="case-study-landing__divide" >
                <section className="o-section-std u-text-center" >
                    <h1 className="type-block-sub-headline" >Нам есть чем гордиться</h1>
                </section>
            </div>
        )
    }
}

export default (CaseStudyLandingDivide);
