import React from 'react';
import classNames from 'classnames';


// import Mailer from '../../../store/mailer'
// import {observer} from 'mobx-react';


class FormContact extends React.Component {

    constructor() {
        super();

        this.state = {
            open: false
        };
        this.handleChange = this.handleChange.bind(this);


    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    getData(form) {
        let elements = form.elements;
        let data = {};
        Object.keys(elements).forEach((key) => {
            let element = elements[key];
            data[element.name] = element.value;
        });
        return data;
    }

    onSubmit(data) {
        // Mailer.sendMailClient(data);
        // this.setState({
        //     open: !this.state.open
        // })
    }

    render() {

        return (
            <div>


                <div className={classNames({
                'sent-allready': this.state.open == true
            })}>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    this.onSubmit(this.getData(e.target))
                }} className="form-contact">
                    <input onChange={this.handleChange} name="name" placeholder="Name" className="form-contact-input mrb20" type="text"/>
                    <input onChange={this.handleChange} name="contacts" placeholder="Phone or Email" className="form-contact-input mrb20" type="text"/>
                    <input type="submit" value="Send" className="button-submit"/>
                </form>
                    <div className="form-sent-already"> Thank you for your application! </div>
            </div>

            </div>

        )
    }
}


export default (FormContact);