import React from 'react';
import {Link} from 'react-router-dom';


class BrancottEstate extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div
                    className="_ie-flex-min-height-fix" >
                    <section
                        className="o-section-full o-section--below-nav brancott-estate" >
                        <div
                            className="brancott-estate__bg-details" ></div>
                        <div
                            className="brancott-estate__feather brancott-estate__feather--a" ><img
                            src="/static/img/case-studies/brancott-estate/feather.png" alt="" /></div>
                        <div
                            className="brancott-estate__feather brancott-estate__feather--b" ><img
                            src="/static/img/case-studies/brancott-estate/feather.png" alt="" /></div>
                        <div
                            className="brancott-estate__feather brancott-estate__feather--c" ><img
                            src="/static/img/case-studies/brancott-estate/feather.png" alt="" /></div>
                        <div
                            className="u-width-limiter" >
                            <header
                                className="case-study-heading-wrapper brancott-estate__heading-wrapper" >
                                <div
                                    className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >
                                        Brancott Estate
                                    </h6>
                                    <h2
                                        className="type-bloc-headline" >
                                        <div >Создание упаковки компании</div>
                                    </h2>
                                    <hr
                                        className="c-hr c-hr--be c-hr--large" />
                                    <a
                                        href="http://pioneers.brancottestate.com/" target="_blank" ><h6
                                        className="type-subheadlines-buttons case-study-heading__link" >pioneers.brancottestate.com</h6></a>
                                    <hr className="c-hr c-hr--be" />
                                </div>
                            </header>

                        </div>
                    </section>
                </div>
                <div
                    className="case-study-sections-wrapper brancott-estate" >
                    <section className="o-section-std" >
                        <div
                            className="u-width-limiter u-width-limiter--low-limited" ><h3
                            className="type-block-sub-headline2" >
                            У Brancott Estate есть неотразимая история, которую мы хотели рассказать;
                            Эта компания стала пионером в Marlborough Sauvignon Blanc, посадив первые виноградные лозы в Мальборо Новой Зеландии.
                        </h3>
                            <hr
                                className="c-hr c-hr--be c-hr--large" />
                            <p
                                className="type-body-copy case-study-limited-paragraph" >
                                Однако у нас возникла проблема.
                                Когда мы спросили свойи клиентов, хотите ли вы услышать нашу версию истории бренда? »Они сказали нет. =)
                                Но мы найдя время все же рассказали свою историю, которая очень понравилась нашим заказчикам.
                            </p></div>
                    </section>
                    <section
                        className="o-section-std" >
                        <div
                            className="u-width-limiter u-width-limiter--high-limited u-no-padding-below-desktop" >
                            <figure
                                className="case-study-figure" >
                                <div >
                                    <video loop autoPlay muted
                                           className="u-image-restraints js-controls-video" >
                                        <source
                                            src="/static/vids/case-studies/brancott-estate.webm" type="video/webm" />
                                        <source
                                            src="/static/vids/case-studies/brancott-estate.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div
                            className="u-width-limiter u-width-limiter--low-limited" ><h3
                            className="type-block-sub-headline2" >
                            Используя убедительный дизайн и новейшие методы HTML5, мы воплотили историю в интерактивную цифровую победу.
                        </h3>
                            <hr
                                className="c-hr c-hr--be c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div
                            className="u-width-limiter u-width-limiter--low-limited" ><p
                            className="type-body-copy u-text-center" >Результаты</p>
                            <div
                                className="case-study-impressions" >
                                <div className="case-study-impressions__group" ><h2 className="type-bloc-headline" >4x</h2><p
                                    className="type-body-copy" >Увеличился трафик сайта</p></div>
                                <div
                                    className="case-study-impressions__group" ><h2
                                    className="type-bloc-headline" >2.5 million</h2><p
                                    className="type-body-copy" >Органический трафик в месяц</p></div>
                                <div
                                    className="case-study-impressions__group" ><h2
                                    className="type-bloc-headline" >+100</h2><p
                                    className="type-body-copy" >Цитируемость сайта и компании в онлайн изданиях</p></div>
                            </div>
                        </div>
                    </section>

                </div>
                <div className=".case-studies-reel" >
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek" to="/case-studies/jacobs-creek" >
                        <h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons" >Обновление сайта Jacob's Creek</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/case-studies/made-by" >
                        <h4 className="type-block-sub-headline" >Made By</h4>
                        <h6 className="type-subheadlines-buttons" >Создание истории бренда «Made By»</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/case-studies/rjsmith" >
                        <h4 className="type-block-sub-headline" >RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons" >Создание бренда</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/case-studies" >
                        <h4 className="type-block-sub-headline" >Кровь, Пот &amp; Слезы</h4>
                        <h6 className="type-subheadlines-buttons" >Посмотреть все наши работы.</h6>
                    </Link>
                </div>
                <div
                    className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (BrancottEstate);
