import React from 'react';

import PrimaryFooterDark from '../../../components/ru/PrimaryFooterDark'
import Whatson from '../../../components/ru/Works/Whatson'
import Navigator from '../../../components/ru/Navigator'
//import Meta from '../../Meta';

class WhatsonC extends React.Component {


    render() {

        return (
            <div className='page-contact'>
                <Navigator/>
                <Whatson/>
                <PrimaryFooterDark/>
            </div>
        )
    }
}

export default (WhatsonC);
