import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
// import Mailer from '../../store/mailer'
// import {observer} from 'mobx-react';




class Navigator extends React.Component {

    constructor() {
        super();

        this.state = {
            open: false,
            sender: false
        };
        this.OpenHamburger = this.OpenHamburger.bind(this)
        this.sendCall = this.sendCall.bind(this)
        this.CloseModal = this.CloseModal.bind(this)
        this.handleChange = this.handleChange.bind(this);

    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    getData(form) {
        let elements = form.elements;
        let data = {};
        Object.keys(elements).forEach((key) => {
            let element = elements[key];
            data[element.name] = element.value;
        });
        return data;
    }

    onSubmit(data) {
        // Mailer.sendMailClient(data);
        // this.CloseModal()
    }

    OpenHamburger(event) {

        this.setState({
            open: !this.state.open

        })

    }

    sendCall() {
        this.setState({
            sender: !this.state.sender

        })
    }

    CloseModal() {
        this.setState({
            sender: false

        })
    }


    render() {

        return (
            <div className={classNames({
                'c-primary-nav--active': this.state.open
            })}>
                <nav className="c-primary-nav">
                    <header className="c-primary-nav__header">
                        <div className="u-width-limiter">
                            <Link to='/en' className="c-primary-footer__logo-wrapper logo-font-white">
                                IDEANIX
                            </Link>
                            <div className="c-primary-nav--right">
                                <div className="header-animate" onClick={this.sendCall}>Submit your application</div>
                                <Link to='/' className="lan-btn">
                                    RU
                                </Link>
                                <div className="c-primary-nav__hamburger js-hamburger" onClick={this.OpenHamburger}>

                                    <div className="c-primary-nav__hamburger-bar c-primary-nav__hamburger-bar--1"></div>
                                    <div className="c-primary-nav__hamburger-bar c-primary-nav__hamburger-bar--2"></div>
                                    <div className="c-primary-nav__hamburger-bar c-primary-nav__hamburger-bar--3"></div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="c-primary-nav__overlay-wrapper-backdrop">
                        <div className="c-primary-nav__overlay-wrapper-backdrop-gradient"></div>
                        <div className="c-primary-nav__overlay-wrapper">
                            <ul className="c-primary-nav__overlay">
                                <Link to="/en">
                                    <li tabIndex="0" className="type-bloc-headline">Home</li>
                                </Link>
                                <Link to="/en/about">
                                    <li tabIndex="0" className="type-bloc-headline">About</li>
                                </Link>
                                <Link to="/en/case-studies">
                                    <li tabIndex="0" className="type-bloc-headline">Works</li>
                                </Link>
                                <Link to="/en/jobs">
                                    <li tabIndex="0" className="type-bloc-headline">Jobs</li>
                                </Link>
                                <Link to="/en/contact">
                                    <li tabIndex="0" className="type-bloc-headline">Contact</li>
                                </Link>
                                {/*<Link to="http://blog.weareimpero.com">*/}
                                {/*<li tabIndex="0" className="type-bloc-headline">Blog</li>*/}
                                {/*</Link>*/}
                            </ul>
                        </div>
                    </div>
                </nav>


                <div className={classNames({
                    'callback-form callback-form--popup active': this.state.sender,
                    'callback-form callback-form--popup': !this.state.sender
                })}>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        this.onSubmit(this.getData(e.target))
                    }} className="main-form form-with-price ajax_form"
                          id="new-home-order-form">
                        <div className="close-button-svg" onClick={this.CloseModal}></div>
                        <div className="big-title big-title--white">DISCUSS THE PROJECT</div>

                        <div className="big-title__subtitle big-title__subtitle--white">Cool projects start with
                            filling in this form
                        </div>
                        <input type="hidden" name="someinfo" value=""/>
                        <input type="hidden" name="titlepage" value="Website development"/>
                        <input type="hidden" name="form-name" value="Discuss the project"/>
                        <input type="hidden" name="require" value=""/>
                        <input type="hidden" name="someinfo" value=""/>
                        <div className="main-form__row">
                            <div className="main-form__col main-form__col--input">
                                <div className="input-group">
                                    <input onChange={this.handleChange} type="text" placeholder="Name"
                                           name="name" className="input-sent-contact"/>
                                </div>
                                <div className="input-group">
                                    <input onChange={this.handleChange} type="text" placeholder="Phone or Email"
                                           name="contacts" className="input-sent-contact"/>
                                </div>
                            </div>
                        </div>
                        <div className="center-btn">
                            <button type="submit" className="main-form__order-btn btn btn--arrow btn--white"
                                    value="Отправить"
                                    name="home-order-form">Send
                                <span className="arrow-right">
                                    <span className="dots dots--right">
                                        <span className="dot dot--right-1"></span>
                                        <span className="dot dot--right-2"></span>
                                        <span className="dot dot--right-3"></span>
                                    </span>
                                    <span className="arrow-right__angle">
                                        <span className="angle angle--right"></span>
                                    </span>
                                </span>
                            </button>
                        </div>
                        <input type="hidden" name="af_action" value="cc92caf512162013ffd61a5244d2d780"/>
                    </form>
                </div>
            </div>
        )
    }
}


export default (Navigator);