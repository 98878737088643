import React from 'react';
import {Link } from 'react-router-dom';


class FullLanding extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav ostopora primark" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper primark__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >Точка опоры</h6>
                                    <h2 className="type-bloc-headline" >Клиника остеопатии в Москве</h2>
                                    <hr className="c-hr c-hr--primark c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >Создание образа компании в цифровом пространстве</h3>
                                </div>
                            </header>
                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Точка опоры - это клиника с многолетним опытом лечения пациентов.</h3>
                            <hr className="c-hr c-hr--primark c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >
                                Нашей основной задачей было создание образа клиники в цифровом пространстве, а также
                                сообщество пациентов и потенциальных клиентов, интересующихся остеопатическими методами лечения.
                                В первую очередь мы создали внутрении контент клиники: видео и фото материалы.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited u-no-padding-below-desktop" >
                            <figure className="case-study-figure" >
                                <div >
                                    <video autoPlay muted loop  poster="/static/img/case-studies/primark/video-thumbnail.jpg" className="u-image-restraints js-controls-video" >
                                        <source src="/static/vids/case-studies/ostopora/ostopora.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Разработка качественного цифрового образа клиники</h3>
                            <hr className="c-hr c-hr--primark c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >
                                Мы создали для наших друзей сайт и страницы в социальных сетях.
                                Наши специалисты проработали SMM политику ведения страниц. Помимо этого, мы внедрили CRM систему для удобного ведения бизнеса и работы с каждым клиентом отдельно.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <img src="/static/img/case-studies/ostopora/opod.png" alt="" className="u-image-full-width case-study-reveal-large-image" />
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <p className="type-body-copy u-text-center" >Результаты</p>
                            <h3 className="type-block-sub-headline2" >
                                Нам удалось повысить конверсию сайта и социальных сетей до 17% от общего трафика.
                                Поток клиентов мы увеличили практически на 300%.
                                По окончанию проделанной работы, мы продлили наш договор на маркетинговое сопровождении клиники. Благодарим за сотрудничество.
                            </h3>
                        </div>
                    </section>
                </div>
                <div className=".case-studies-reel" >
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek" to="/case-studies/jacobs-creek" >
                        <h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons" >Обновление сайта Jacob's Creek</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/case-studies/made-by" >
                        <h4 className="type-block-sub-headline" >Made By</h4>
                        <h6 className="type-subheadlines-buttons" >Создание истории бренда «Made By»</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/case-studies/rjsmith" >
                        <h4 className="type-block-sub-headline" >RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons" >Создание бренда</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/case-studies" >
                        <h4 className="type-block-sub-headline" >Кровь, Пот &amp; Слезы</h4>
                        <h6 className="type-subheadlines-buttons" >Посмотреть все наши работы.</h6>
                    </Link>
                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (FullLanding);
