import React from 'react';
import {Link} from 'react-router-dom';

class PageCaseStudyLandingHero extends React.Component {


    render() {

        return (
            <section className="case-study-landing-half-blocks-wrapper">
                <div className="c-work-list">
                    <ul className="c-work-list__list">
                        <li className="c-work-list__item c-work-list__item--needcars c-work-list__item--featured">
                            <Link className="c-work-list__item-link" to="/case-studies/needcars">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Need-Cars</h6>
                                                        <h3 className="type-bloc-headline fw300">Need-Cars подбор автомобилей</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--ostopora">
                            <Link className="c-work-list__item-link" to="/case-studies/ostopora">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Точка опоры</h6>
                                                        <h3 className="type-bloc-headline fw300">Клиника остеопатии Точка опоры</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--scopus">
                            <Link className="c-work-list__item-link" to="/case-studies/scopus">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Scopus CRM</h6>
                                                        <h3 className="type-bloc-headline fw300">Система автоматизированного учета публикаций</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--primark">
                            <Link className="c-work-list__item-link" to="/case-studies/primark">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Primark</h6>
                                                        <h3 className="type-bloc-headline fw300">Размещение видео в прямом эфире в рамках рождественской кампании</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        {/*<li className="c-work-list__item c-work-list__item--7-up">*/}
                        {/*<a className="c-work-list__item-link" href="/case-studies/7-up">*/}
                        {/*<span className="c-work-list__item-bg"></span>*/}
                        {/*<span className="c-work-list__item-wrapper">*/}
                        {/*<span className="c-work-list__item-inner">*/}
                        {/*<h6 className="type-subheadlines-buttons">7-up</h6>*/}
                        {/*<h3 className="type-bloc-headline">7-up verses the heat</h3>*/}
                        {/*<hr className="c-hr c-hr--white c-hr--large"/>*/}
                        {/*</span>*/}
                        {/*</span>*/}
                        {/*</a>*/}
                        {/*</li>*/}
                        {/*<li className="c-work-list__item c-work-list__item--tube-strike">*/}
                            {/*<Link className="c-work-list__item-link" href="/case-studies/tube-strike">*/}
                                {/*<span className="c-work-list__item-bg"></span>*/}
                                {/*<span className="c-work-list__item-wrapper">*/}
                                                    {/*<span className="c-work-list__item-inner">*/}
                                                        {/*<h6 className="type-subheadlines-buttons">Внутренний проект</h6>*/}
                                                        {/*<h3 className="type-bloc-headline fw300">ВИРУСНЫЙ*/}
{/*КОНТЕНТ</h3>*/}
                                                        {/*<hr className="c-hr c-hr--white c-hr--large"/>*/}
                                                    {/*</span>*/}
                                                {/*</span>*/}
                            {/*</Link>*/}
                        {/*</li>*/}
                        <li className="c-work-list__item c-work-list__item--jacobs-creek">
                            <Link className="c-work-list__item-link" to="/case-studies/jacobs-creek">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Jacob&#x27;s Creek</h6>
                                                        <h3 className="type-bloc-headline fw300">Обновление сайта Jacob's Creek</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--whatson">
                            <Link className="c-work-list__item-link" to="/case-studies/whatson">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Flicks.co.nz</h6>
                                                        <h3 className="type-bloc-headline fw300">Создание Facebook Чат Бот</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--cpj">
                            <Link className="c-work-list__item-link" to="/case-studies/cpj">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">CPJ</h6>
                                                        <h3 className="type-bloc-headline fw300">Запуск нового сайта
                                                            <br/>соковой компании
                                                        </h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--brancott-estate">
                            <Link className="c-work-list__item-link" to="/case-studies/brancott-estate">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Brancott Estate</h6>
                                                        <h3 className="type-bloc-headline fw300">Создание упаковки компании</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--popchips">
                            <Link className="c-work-list__item-link" to="/case-studies/popchips">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">PopChips</h6>
                                                        <h3 className="type-bloc-headline fw300">Запуск PR кампании</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--made-by">
                            <Link className="c-work-list__item-link" to="/case-studies/made-by">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Jacob&#x27;s Creek</h6>
                                                        <h3 className="type-bloc-headline fw300">Создание истории бренда «Made By»</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--beefeater">
                            <Link className="c-work-list__item-link" to="/case-studies/beefeater">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">Beefeater</h6>
                                                        <h3 className="type-bloc-headline fw300">
                                                          Возвращение<br/>Beefeater Gin (бренда)<br/>к успеху
                                                        </h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                        <li className="c-work-list__item c-work-list__item--rjsmith">
                            <Link className="c-work-list__item-link" to="/case-studies/rjsmith">
                                <span className="c-work-list__item-bg"></span>
                                <span className="c-work-list__item-wrapper">
                                                    <span className="c-work-list__item-inner">
                                                        <h6 className="type-subheadlines-buttons">RJ Smith</h6>
                                                        <h3 className="type-bloc-headline fw300">Создание бренда</h3>
                                                        <hr className="c-hr c-hr--white c-hr--large"/>
                                                    </span>
                                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default (PageCaseStudyLandingHero);
