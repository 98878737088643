import React from 'react';


class AboutHowWeDoIt extends React.Component {


    render() {

        return (
            <div className="_ie-flex-min-height-fix" >
                <section className="o-section-full about-how-we-do-it" >
                    <div className="u-width-limiter" >
                        <div className="about-how-we-do-it__content" >
                            <h6 className="type-subheadlines-buttons" >Как мы это делаем</h6>
                            <h2 className="type-bloc-headline" ><div >Мы отдаем<br/>наивысший приоритет покупателям,<br/>игнорируюя все условности</div></h2>
                            <h6 className="type-subheadlines-buttons" >и заставляем клиентов любить вас.</h6>
                            <h6 className="type-block-sub-headline2" ><div >
                                Наша проприетарная модель Fame and Glory предназначена для изменения направления развития брендов путем поиска нового источника трафика и новых клиентов для вас.
                                Мы разрабатываем стратегию, ингнорируя условные границы и думаем в первую очередь о покупателе услуг.
                                Мы считаем, что любой бренд или компания, при правильной стратегии развития и качественной работе, может стать известным во всем мире.
                            </div></h6>
                            <hr className="c-hr c-hr--black c-hr--large"  />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default (AboutHowWeDoIt);