import React from 'react';
import {Link} from 'react-router-dom';
import {MallerOptimMp4, MallerOptimWebm, MalletImage} from "../../FlowMedia";

class HomeWork extends React.Component {


    render() {

        return (
            <div
                className="_ie-flex-min-height-fix">


                <section className="o-section-full home-work">
                    <div className="u-width-limiter">
                        <div className="c-section-video-fill-wrapper">
                            <video loop="loop" autoPlay muted poster={MalletImage}>
                                <source
                                    src={MallerOptimWebm} type="video/webm"/>
                                <source
                                    src={MallerOptimMp4} type="video/mp4"/>
                            </video>
                        </div>
                        <div className="home-work__content"><h6 className="type-subheadlines-buttons">Smashing it</h6>
                            <h2
                                className="type-hero-headline">
                                <div>Blood<br/>Sweat<br/>and Tears
                                </div>
                            </h2>
                            <hr className="c-hr c-hr--yellow c-hr--large"/>
                            <Link to="/en/case-studies">
                                <h6 className="type-subheadlines-buttons">See our works</h6>
                            </Link>
                            <hr className="c-hr c-hr--black"/>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default (HomeWork);
