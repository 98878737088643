import React from 'react';


class ContactHeading extends React.Component {


    render() {

        return (
            <section className="o-section-full o-section--below-nav contact-heading" >
                <div className="u-width-limiter u-width-limiter--low-limited" >
                    <header >
                        <h2 className="type-bloc-headline" ><div >We're based in MOSCOW<br/>and ST. PETERSBURG.</div></h2>
                        <hr className="c-hr c-hr--yellow c-hr--large"  />
                        <h3 className="type-block-sub-headline2" >We work with friends and clients all over the world</h3><a href="#contact" ><h6 className="type-subheadlines-buttons contact-header__link" >Contact us</h6></a>
                        <hr className="c-hr c-hr--black"  />
                    </header>
                </div>
            </section>
        )
    }
}

export default (ContactHeading);