import React from 'react';
import PrimaryFooter from './PrimaryFooter'

class PrimaryFooterDark extends React.Component {


    render() {

        return (
            <div>
                <section className="o-section-std o-section--before-footer" >
                    <div id="contact" className="u-anchor" ></div>
                    <div className="u-width-limiter u-width-limiter--low-limited" >
                        <div className="contact-location" >
                            <div className="contact-location__primary" >
                                <h2 className="type-bloc-headline" >Москва</h2>
                                <hr className="c-hr c-hr--yellow c-hr--large" />
                                <p className="type-body-copy" >
                                    Большой Саввинский

                                    <br />
                                    пер. д.9
                                    <h6 className="type-body-copy" ><a
                                        href="mailto:moscow@ideanix.ru">moscow@ideanix.ru</a>
                                    </h6>

                                    <br /><span className="contact-location__primary-phone" ><a href="tel:+79859928325" >+7 985 992 83 25</a><span >+7 985 992 83 25</span></span>
                                </p><a href="https://goo.gl/maps/BAvQoXLB7T82" target="_blank" ><h6
                                className="type-subheadlines-buttons" >Посмотреть на карте</h6></a>
                                <hr className="c-hr c-hr--black" />
                            </div>
                            <div className="contact-location__side-blocks-wrapper" >
                                <div className="contact-location__side-block" >
                                    <h4 className="type-block-sub-headline" >Работать с нами</h4>
                                    <p className="type-body-copy" ><a
                                        href="mailto:workwithus@ideanix.ru" >workwithus@ideanix.ru</a></p>
                                </div>
                                <div className="contact-location__side-block" >
                                    <h4 className="type-block-sub-headline" >Карьера</h4>
                                    <p className="type-body-copy" ><a
                                        href="mailto:jobs@weareimpero.com" >jobs@ideanix.ru</a>
                                    </p>
                                </div>
                                <div className="contact-location__side-block" >
                                    <h4 className="type-block-sub-headline" >Ваши комментарии </h4>
                                    <p className="type-body-copy" ><a
                                        href="mailto:hello@weareimpero.com" >hello@ideanix.ru</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="contact-location" >
                            <div className="contact-location__primary" >
                                <h2 className="type-bloc-headline" >Санкт-Петербург</h2>
                                <hr className="c-hr c-hr--yellow c-hr--large" />
                                <p className="type-body-copy" >
                                    Лермонтовский

                                    <br />
                                    пр. 9
                                    <h6 className="type-body-copy">
                                        <a href="mailto:spb@ideanix.ru">spb@ideanix.ru</a></h6>

                                    <br /><span className="contact-location__primary-phone" ><a href="tel:+79859928325" >+7 985 992 83 25</a><span >+7 985 992 83 25</span></span>
                                </p><a href="https://goo.gl/maps/qGkck7hzcdT2" target="_blank" ><h6
                                className="type-subheadlines-buttons" >Посмотреть на карте</h6></a>
                                <hr className="c-hr c-hr--black" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default (PrimaryFooterDark);