import React from 'react';
import {Link} from 'react-router-dom';


class FullLanding extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav primark" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper primark__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >Primark</h6>
                                    <h2 className="type-bloc-headline" >Живое рождество</h2>
                                    <hr className="c-hr c-hr--primark c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >Размещение видео в прямом эфире в рамках рождественской кампании</h3>
                                </div>
                            </header>
                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Primark имеет очень лояльных зрителей как в онлайне режиме, так и в офлайн.</h3>
                            <hr className="c-hr c-hr--primark c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >
                                Наша задача состояла в том, чтобы вовлечь эту аудиторию в период Рождества, создать шум, не конкурируя с обычными телевизионными роликами.
                                Итак, совместно с Primark мы содали онлайн трансляции в сообществах. Материалом трансляций послужили живые выступления сотрудников.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited u-no-padding-below-desktop" >
                            <figure className="case-study-figure" >
                                <div >
                                    <video autoPlay muted loop  poster="/static/img/case-studies/primark/video-thumbnail.jpg" className="u-image-restraints js-controls-video" >
                                        <source src="/static/vids/case-studies/primark.webm" type="video/webm" />
                                        <source src="/static/vids/case-studies/primark.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Новый формат нам очень понравился.</h3>
                            <hr className="c-hr c-hr--primark c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >
                                Мы хотели привлечь аудиторию и создать живой диалог и двухсторонний, захватывающий,
                                телевизионный опыт - подобный, о котором мы все помним, как дети из субботнего утреннего телевизора.
                                В течение дня мы провели пять 30-минутных эпизодов, разбросанных с утра до вечера,
                                чтобы получить максимальный охват нашей разнообразной аудитории.
                                В роликах участововали два основных ведущих и два вспомонательных, это было также, как и
                                в любой другой телевизионной программе, работа команды соответсвовала самому высокому уровню.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <img src="/static/img/case-studies/primark/devices.jpg" alt="" className="u-image-full-width case-study-reveal-large-image" />
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <p className="type-body-copy u-text-center" >Результаты</p>
                            <h3 className="type-block-sub-headline2" >
                                В течение дня наши пять 30-минутных «показов» достигли в общей сложности 3,7 миллиона пользователей органичного трафика и набрали более 320 000 уникальных просмотров видео.
                                Что касается взаимодействия с аудиторией, мы создали целых 5000+ комментариев и взаимодействий в потоке всего за день.
                            </h3>
                        </div>
                    </section>
                </div>
                <div className=".case-studies-reel" >
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek" to="/case-studies/jacobs-creek" >
                        <h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons" >Обновление сайта Jacob's Creek</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/case-studies/made-by" >
                        <h4 className="type-block-sub-headline" >Made By</h4>
                        <h6 className="type-subheadlines-buttons" >Создание истории бренда «Made By»</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/case-studies/rjsmith" >
                        <h4 className="type-block-sub-headline" >RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons" >Создание бренда</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/case-studies" >
                        <h4 className="type-block-sub-headline" >Кровь, Пот &amp; Слезы</h4>
                        <h6 className="type-subheadlines-buttons" >Посмотреть все наши работы.</h6>
                    </Link>
                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (FullLanding);
