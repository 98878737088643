import React from 'react';
import {Link} from 'react-router-dom';



class Popchips extends React.Component {


    render() {

        return (
            <div className="page-case-study">
                <div className="_ie-flex-min-height-fix">
                    <section className="o-section-full o-section--below-nav popchips-landing">
                        <div className="popchips-chip popchips-chip--a"><img
                            src="/static/img/case-studies/popchips/chip.png"/></div>
                        <div className="popchips-chip popchips-chip--b"><img
                            src="/static/img/case-studies/popchips/chip.png"/></div>
                        <div className="popchips-chip popchips-chip--c"><img
                            src="/static/img/case-studies/popchips/chip.png"/></div>
                        <div className="popchips-packets"></div>
                        <div className="u-width-limiter">
                            <header className="case-study-heading-wrapper popchips-landing__heading-wrapper">
                                <div className="case-study-heading">
                                    <h6 className="type-subheadlines-buttons">PopChips</h6>
                                    <h2 className="type-bloc-headline">Запуск PR кампании</h2>
                                    <hr className="c-hr c-hr--yellow c-hr--large"/>
                                    <h3 className="type-block-sub-headline2">Делайте британские обеды веселыми.</h3></div>
                            </header>
                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper">
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited">
                            <h3 className="type-block-sub-headline2">
                                Задача бренда нацелится на рынок обеденного перекуса.
                            </h3>
                            <hr className="c-hr c-hr--yellow c-hr--large"/>
                            <p className="type-body-copy case-study-limited-paragraph">
                                Наше исследование показало, что многие люди были не довольны своими завтраками. По этому поводу даже был запушенна акция в Instagram и Twitter #SadDeskLunch. Мы увидели в этом возможность и запустили кампанию #PopMyLunch.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--high-limited">
                            <figure className="case-study-figure"><img
                                src="/static/img/case-studies/popchips/popmylunch.jpg" className="u-image-restraints"/></figure>
                        </div>
                    </section>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited">
                            <h3 className="type-block-sub-headline2">
                                Мы наняли Хосе Мендеса, лондонского художника и иллюстратора, чтобы помочь сделать грустные настольные персоонажи для акции #PopMyLunch.
                            </h3>
                            <hr className="c-hr c-hr--yellow c-hr--large"/>
                            <p className="type-body-copy case-study-limited-paragraph">
                                После двух недель проведенной акции мы нашли самые грустные обеды и разыграли среди них призы.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited"><img
                            src="/static/img/case-studies/popchips/social.jpg" className="u-image-restraints"/></div>
                    </section>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited"><img
                            src="/static/img/case-studies/popchips/packets.png" className="u-image-restraints"/></div>
                    </section>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited">
                            <h3 className="type-block-sub-headline2">
                                Кампания была выполнена с учетом небольшого бюджета.
                                Чтобы максимизировать эффект нашей работы, мы задействовали все наше влияние, в сфере традиционныx
                                средств массовой информации и социальныx сетях,
                                чтобы обеспечить доступность и достоверность нашего сообщения.
                            </h3>
                            <hr className="c-hr c-hr--yellow c-hr--large"/>
                        </div>
                    </section>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited">
                            <p className="type-body-copy u-text-center">Результаты</p>
                            <h3 className="type-block-sub-headline2">
                                Мы достигли более трех миллионов участников в акции #PopMyLunch.
                            </h3>
                            <hr className="c-hr c-hr--yellow c-hr--large"/>
                        </div>
                    </section>

                </div>
                <div className=".case-studies-reel">
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek" to="/case-studies/jacobs-creek">
                        <h4 className="type-block-sub-headline">Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons">Обновление сайта Jacob's Creek</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/case-studies/made-by">
                        <h4 className="type-block-sub-headline">Made By</h4>
                        <h6 className="type-subheadlines-buttons">Создание истории бренда «Made By»</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/case-studies/rjsmith">
                        <h4 className="type-block-sub-headline">RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons">Создание бренда</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/case-studies">
                        <h4 className="type-block-sub-headline">Кровь, Пот &amp; Слезы</h4>
                        <h6 className="type-subheadlines-buttons">Посмотреть все наши работы.</h6>
                    </Link>
                </div>
                <div className="u-footer-spacer"></div>
            </div>
        )
    }
}

export default (Popchips);
