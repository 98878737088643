import React from 'react';
import {
    CrowImage, CrownOptimMp4, CrownOptimWebm
} from '../../FlowMedia/index'

class AboutLanding extends React.Component {


    render() {

        return (
            <div className="_ie-flex-min-height-fix" >
                <section className="o-section-full o-section--below-nav about-landing" >
                    <div className="u-width-limiter" >
                        <div className="c-section-video-fill-wrapper" >
                            <video autoPlay loop="loop" muted poster={CrowImage} data-load-time-perc="auto" >
                                <source src={CrownOptimWebm} type="video/webm"  />
                                <source src={CrownOptimMp4} type="video/mp4"  />
                            </video>
                        </div>
                        <header className="about-landing__content" >
                            <h6 className="type-subheadlines-buttons" >Мы Ideanix.</h6>
                            <span className="type-intro-headline" >Одержимы,</span>
                            <span className="type-intro-headline" >креативны,</span><span className="type-intro-headline" >амбициозны,</span><span className="type-intro-headline" >технологичны
                        </span>
                            {/*<span className="type-intro-headline" >КЛИЕНТООРИЕНТИРОВАНЫ.</span>*/}
                            {/*из за этого слова едет верстка на мобльном*/}

                            <hr className="c-hr c-hr--yellow c-hr--large"  />
                        </header>
                    </div>
                </section>
            </div>
        )
    }
}

export default (AboutLanding);
