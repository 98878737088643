import React from 'react';

import PrimaryFooterDark from '../../../components/ru/PrimaryFooterDark'
import Rjsmith from '../../../components/ru/Works/Rjsmith'
import Navigator from '../../../components/ru/Navigator'

//import Meta from '../../Meta';
class RjsmithC extends React.Component {


    render() {

        return (
            <div className='page-contact'>
                <Navigator/>
                <Rjsmith/>
                <PrimaryFooterDark/>
            </div>
        )
    }
}

export default (RjsmithC);
