import React from 'react';
import ContactHeading from './ContactHeading'
import PrimaryFooterDark from '../PrimaryFooterDark'

class FullLanding extends React.Component {


    render() {

        return (
            <div className='page-contact'>
                <ContactHeading/>
                <PrimaryFooterDark/>
            </div>
        )
    }
}

export default (FullLanding);
