import React from 'react';

import PrimaryFooterDark from '../../../components/en/PrimaryFooterDark'
import Rjsmith from '../../../components/en/Works/Rjsmith'
import Navigator from '../../../components/en/Navigator'
//import Meta from '../../Meta';

class RjsmithC extends React.Component {


    render() {

        return (
            <div className='page-contact'>
                <Navigator/>
                <Rjsmith/>
                <PrimaryFooterDark/>
            </div>
        )
    }
}

export default (RjsmithC);
