import React from 'react';
import {Link} from 'react-router-dom';


class Rjsmith extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav rjsmith-landing" >
                        <div className="rjsmith-grid rjsmith-grid--a" ></div>
                        <div className="rjsmith-grid rjsmith-grid--b" ></div>
                        <div className="rjsmith-foreground" ></div>
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper rjsmith-heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >RJSmith</h6>
                                    <h2 className="type-bloc-headline" >
                                        <div >RJSmith,<br/>Создание<br/>Бренда</div>
                                    </h2>
                                    <hr className="c-hr c-hr--white c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >
                                        Создание истории престижного бренда.
                                    </h3><a href="http://www.rjsmith.co.uk/" target="_blank" ><h6
                                    className="type-subheadlines-buttons case-study-heading__link" >rjsmith.co.uk</h6></a>
                                    <hr className="c-hr c-hr--peach" />
                                </div>
                            </header>
                            <div className="u-width-limiter u-width-limiter--low-limited u-no-padding-below-desktop" >
                                <h3 className="type-block-sub-headline2" >
                                    Описание задачи кажется совсем простым: сделать бренд RJ Smith и присутствие его в Интернете престижным, как дома, которые они строят.
                                </h3>
                                <hr className="c-hr c-hr--peach c-hr--large" />
                            </div>
                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                Работы RJ Smith потрясающие, но стиль подачи инфромации в интернете не отображал высокий уровень компании.
                                Наша стратегия заключалась в том, чтобы провести исследование и показать их работу компании во всей красе.
                            </h3>
                            <hr className="c-hr c-hr--peach c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited" ><img
                            src="/static/img/case-studies/rjsmith/wooden-house.jpg" className="u-image-restraints" />
                            <div className="rjsmith-split-media" >
                                <div ><img src="/static/img/case-studies/rjsmith/stone-house.jpg"
                                                            className="u-image-restraints" /></div>
                                <div >
                                    <video loop autoPlay className="u-image-restraints" >
                                        <source src="/static/vids/case-studies/rjsmith-drone.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                Созданный нами бренд был и современным, и строгим одновременно.
                            </h3>
                            <hr className="c-hr c-hr--peach c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited" ><img
                            src="/static/img/case-studies/rjsmith/brand.jpg" className="u-image-restraints" /></div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                Новый адаптивный веб-сайт с потрясающим наполнением помог передать потрясающую работу RJ Smith.
                            </h3>
                            <hr className="c-hr c-hr--peach c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/rjsmith/devices.png"
                        alt="RJSmith website preview in several devices."
                        className="u-image-full-width case-study-reveal-large-image" /></section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited" >
                            <figure className="case-study-figure" >
                                <div >
                                    <video controls="" className="u-image-restraints js-controls-video" >
                                        <source src="/static/vids/case-studies/rjsmith.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                </div>
                <div className=".case-studies-reel" >
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek" to="/case-studies/jacobs-creek" >
                        <h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons" >Обновление сайта Jacob's Creek</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/case-studies/made-by" >
                        <h4 className="type-block-sub-headline" >Made By</h4>
                        <h6 className="type-subheadlines-buttons" >Создание истории бренда «Made By»</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/case-studies/rjsmith" >
                        <h4 className="type-block-sub-headline" >RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons" >Создание бренда</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/case-studies" >
                        <h4 className="type-block-sub-headline" >Кровь, Пот &amp; Слезы</h4>
                        <h6 className="type-subheadlines-buttons" >Посмотреть все наши работы.</h6>
                    </Link>
                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (Rjsmith);
