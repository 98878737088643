import React from 'react';
import HomeLanding from './HomeLanding'
import HomeWork from './HomeWork'
import HomeBlog from './HomeBlog'
import Footer from '../Footer'
class FullLanding extends React.Component {


    render() {

        return (
            <div className='page page-home'>
                <HomeLanding/>
                <HomeWork/>
                <HomeBlog/>
                <Footer/>
            </div>
        )
    }
}

export default (FullLanding);
