import React, { Component } from 'react';
import Preloader from '../../components/ru/Preloader'
import Navigator from '../../components/ru/Navigator'
import Jobs from '../../components/ru/Jobs'
import FooterOr from '../../components/ru/FooterOr'
//import Meta from '../Meta';


class About extends Component {
    render() {
        return (
            <div className="App">
                <Navigator/>
                <Jobs/>
                <FooterOr/>
            </div>
        );
    }
}

export default About;
