import React from 'react';
import {Link} from 'react-router-dom';


class FullLanding extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav cpj" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper cpj__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >CPJ: COLD PRESSED JUICE</h6>
                                    <h2 className="type-bloc-headline" >
                                        <div >Запуск нового сайта соковой компании.</div>
                                    </h2>
                                    <hr className="c-hr c-hr--cpj" />
                                </div>
                            </header>

                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Мы начали работать с имени бренда и закончили созданием полной упакови бренда, включая упаковку товара, веб-сайт и план запуска рекламы.</h3>
                            <hr className="c-hr c-hr--cpj c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >
                                Монограмма «Форт» была вдохновлена оружием Святого Иоанна, символом обороны и силы. Но вы, вероятно, знаете это лучше, как обычный цифровой значок «command» на Mac.
                            </p>
                            <p className="type-body-copy case-study-limited-paragraph" >
                                С этого момента мы начали создание бренда с нуля, разрабатывая упаковку, мобильное приложение, сайт с системой заказов. Мы хотели создать гибкий и легкий продук.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited u-no-padding-below-desktop" >
                            <div className="c-mediagrid" >
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/monogram.png" alt="CPJ monogram" className="u-image-restraints" />
                                </div>
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/logo.png" alt="CPJ logo" className="u-image-restraints" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-no-padding-below-desktop" >
                            <div className="c-mediagrid" >
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/bottle-shift.jpg" id="cpj-cs-branding-mg-0" className="u-image-restraints" />
                                </div>
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/bottle-refresh.jpg" id="cpj-cs-branding-mg-1" className="u-image-restraints" />
                                </div>
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/bottle-eject.jpg" id="cpj-cs-branding-mg-2" className="u-image-restraints" />
                                </div>
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/business-cards.jpg" id="cpj-cs-branding-mg-3" className="u-image-restraints" />
                                </div>
                                <div className="c-mediagrid__item" >
                                    <img src="/static/img/case-studies/cpj/ingredients.jpg" id="cpj-cs-branding-mg-4" className="u-image-restraints" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Сайт, интернет магазин и работа над брендом.</h3>
                            <hr className="c-hr c-hr--cpj c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >
                                Сайт CPJ был запущен как основная точка входа для интернет магазина.
                                Мы позаботились о дизайне, разработке интерфейса и пользовательском опыте на сайте.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <img src="/static/img/case-studies/cpj/devices.jpg" alt="CPJ website preview in several devices." className="u-image-full-width case-study-reveal-large-image u-tablet-and-up-only" />
                        <img src="/static/img/case-studies/cpj/devices-mobile.jpg" alt="CPJ website preview in several devices." className="u-image-full-width case-study-reveal-large-image u-mobile-only" />
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                Для запуска мы создали сервис CPJ Juice - интерактивное путешествие, которое позволяет потребителям находить правильный сок для своего тела, исходя из образа жизни или фитнес-режима.
                            </h3>
                            <hr className="c-hr c-hr--cpj c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited u-no-padding-below-desktop" >
                            <figure className="case-study-figure" >
                                <div >
                                    <video controls="" loop="loop" className="u-image-restraints js-controls-video" autoPlay>
                                        <source src="/static/vids/case-studies/cpj.webm" type="video/webm" />
                                        <source src="/static/vids/case-studies/cpj.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>

                </div>
                <div className=".case-studies-reel" >
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek" to="/case-studies/jacobs-creek" >
                        <h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons" >Обновление сайта Jacob's Creek</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/case-studies/made-by" >
                        <h4 className="type-block-sub-headline" >Made By</h4>
                        <h6 className="type-subheadlines-buttons" >Создание истории бренда «Made By»</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/case-studies/rjsmith" >
                        <h4 className="type-block-sub-headline" >RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons" >Создание бренда</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/case-studies" >
                        <h4 className="type-block-sub-headline" >Кровь, Пот &amp; Слезы</h4>
                        <h6 className="type-subheadlines-buttons" >Посмотреть все наши работы.</h6>
                    </Link>
                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (FullLanding);
