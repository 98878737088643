import React from 'react';


class ContactHeading extends React.Component {


    render() {

        return (
            <section className="o-section-full o-section--below-nav contact-heading" >
                <div className="u-width-limiter u-width-limiter--low-limited" >
                    <header >
                        <h2 className="type-bloc-headline" >
                            <div >Мы находимся в Москве<br/>и Санкт-Петербурге</div>
                        </h2>
                        <hr className="c-hr c-hr--yellow c-hr--large" />
                        <h3 className="type-block-sub-headline2" >Мы работаем с друзьями и клиентами по
                            всему миру</h3><a href="#contact" ><h6
                        className="type-subheadlines-buttons contact-header__link" >Связаться с нами</h6></a>
                        <hr className="c-hr c-hr--black" />
                    </header>
                </div>
            </section>
        )
    }
}

export default (ContactHeading);