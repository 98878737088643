import React from 'react';

import PrimaryFooterDark from '../../../components/en/PrimaryFooterDark'
import JacobsCreek from '../../../components/en/Works/JacobsCreek'
import Navigator from '../../../components/en/Navigator'
//import Meta from '../../Meta';

class JacobsCreekC extends React.Component {


    render() {

        return (
            <div className='page-contact'>
                <Navigator/>
                <JacobsCreek/>
                <PrimaryFooterDark/>
            </div>
        )
    }
}

export default (JacobsCreekC);
