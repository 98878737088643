import React from 'react';
import {Link} from 'react-router-dom';


class FullLanding extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav ostopora primark" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper primark__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >Точка опоры</h6>
                                    <h2 className="type-bloc-headline" >Clinic of Osteopathy in Moscow</h2>
                                    <hr className="c-hr c-hr--primark c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >Creating an image of the company in the digital environment</h3>
                                </div>
                            </header>
                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Точка опоры - it is a clinic with many years of experience in the treatment of patients.</h3>
                            <hr className="c-hr c-hr--primark c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >
                                Our main task was to create an image of the clinic in the digital sphere.
                                Creation of community of patients and potential clients interested in osteopathic methods of treatment. First, we created the internal content of the clinic, both video and photo content.
                            </p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited u-no-padding-below-desktop" >
                            <figure className="case-study-figure" >
                                <div >
                                    <video autoPlay muted loop  poster="/static/img/case-studies/primark/video-thumbnail.jpg" className="u-image-restraints js-controls-video" >
                                        <source src="/static/vids/case-studies/ostopora/ostopora.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Creating a decent digital image of the clinic</h3>
                            <hr className="c-hr c-hr--primark c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >
                                We created a site and social networking sites for our friends.
                                Our experts have developed a SMM page management policy. We also implemented CRM system for convenient business management, and work with each client separately.     </p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <img src="/static/img/case-studies/ostopora/opod.png" alt="" className="u-image-full-width case-study-reveal-large-image" />
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <p className="type-body-copy case-study-limited-paragraph" >
                                Completed task with a limited budget, we built a campaign to ensure the best results for the money entrusted to us, we were able to attract "Live traffic". Thanks to the thorough scrutiny of competitors and the roar of our customers, we were able to not sacrifice the quality of work to cover the largest number of audiences.                            </p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <p className="type-body-copy u-text-center" >Results</p>
                            <h3 className="type-block-sub-headline2" >
                                We managed to improve the conversion of the site and social networks to 17% of the total traffic. We increased traffic by almost 300%. Upon termination of works, we have signed the contract on marketing support of clinic for a year. Thank you for your cooperation...
                            </h3>
                        </div>
                    </section>
                </div>
                <div className=".case-studies-reel" >
                    <Link
                        className="case-studies-reel__item case-studies-reel__item--made-by" to="/en/case-studies/made-by" ><h4 className="type-block-sub-headline" >Made By</h4><h6
                        className="type-subheadlines-buttons" >Campaign</h6></Link>

                    <Link
                        className="case-studies-reel__item case-studies-reel__item--tube-strike"
                        to="/en/case-studies/tube-strike" ><h4 className="type-block-sub-headline" >F*cking Tube Strike</h4>
                        <h6
                            className="type-subheadlines-buttons" >Reactive</h6></Link>

                    <Link
                        className="case-studies-reel__item case-studies-reel__item--jacobs-creek"
                        to="/en/case-studies/jacobs-creek" ><h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                        <h6
                            className="type-subheadlines-buttons" >Website Redesign</h6></Link>

                    <Link
                        className="case-studies-reel__item case-studies-reel__item--more" to="/en/case-studies" ><h4 className="type-block-sub-headline" >Blood, Sweat &amp; Tears</h4><h6
                        className="type-subheadlines-buttons" >See all our work.</h6></Link>

                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (FullLanding);
