import React from 'react';


class PrimaryFooter extends React.Component {


    render() {

        return (
            <div>
                <div className="u-footer-spacer" ></div>
                <footer className="c-primary-footer c-primary-footer--dark" id="" >
                    <div className="u-width-limiter" >
                        <ul className="c-primary-footer__content" >
                            <li >
                                <div className="c-primary-footer__logo-wrapper logo-font" ><span className="isvg pending" >

                           IDEANIX
                                </span>
                                </div>
                                <p className="type-body-copy footer-slogan" >We make tired brands famous again.
                                </p>
                                {/*<header >*/}
                                    {/*<h6 className="type-subheadlines-buttons" >Follow*/}
                                        {/*us</h6></header>*/}
                                {/*<a href="" target="_blank" >*/}
                                    {/*<div className="c-primary-footer__content-social-item" ><span className="isvg pending" >*/}
                                        {/*<svg id="Arrow___-223675275" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">*/}
    {/*<defs>*/}

    {/*</defs>*/}
    {/*<title>linkedin</title>*/}
    {/*<path className="cls-1" d="M24.24,46H18.93V26.5h5.32V46ZM21.59,24.25a3.13,3.13,0,1,1,3.1-3.13A3.12,3.12,0,0,1,21.59,24.25ZM45.52,46H40.2V36.07c0-6-7.09-5.52-7.09,0V46H27.79V26.5h5.32v3.13C35.58,25,45.52,24.71,45.52,34V46Z"></path>*/}
    {/*<path className="cls-1" d="M32,4A28,28,0,1,1,4,32,28,28,0,0,1,32,4m0-4A32,32,0,1,0,64,32,32,32,0,0,0,32,0h0Z"></path>*/}
{/*</svg>*/}
                                    {/*</span></div>*/}
                                {/*</a>*/}
                                {/*<a href="https://www.instagram.com/ideanix/" target="_blank" >*/}
                                    {/*<div className="c-primary-footer__content-social-item" ><span className="isvg pending" >*/}
                                        {/*<svg id="Arrow___-326929593" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">*/}
    {/*<defs>*/}

    {/*</defs>*/}
    {/*<title>instagram</title>*/}
    {/*<path className="cls-1" d="M32,4A28,28,0,1,1,4,32,28,28,0,0,1,32,4m0-4A32,32,0,1,0,64,32,32,32,0,0,0,32,0h0Z"></path>*/}
    {/*<path className="cls-1" d="M32,15.48c5.38,0,6,0,8.14.12a11.15,11.15,0,0,1,3.74.69,6.67,6.67,0,0,1,3.82,3.82,11.15,11.15,0,0,1,.69,3.74c0.1,2.13.12,2.76,0.12,8.14s0,6-.12,8.14a11.15,11.15,0,0,1-.69,3.74,6.67,6.67,0,0,1-3.82,3.82,11.15,11.15,0,0,1-3.74.69c-2.12.1-2.76,0.12-8.14,0.12s-6,0-8.14-.12a11.15,11.15,0,0,1-3.74-.69,6.67,6.67,0,0,1-3.82-3.82,11.15,11.15,0,0,1-.69-3.74C15.5,38,15.48,37.38,15.48,32s0-6,.12-8.14a11.15,11.15,0,0,1,.69-3.74,6.67,6.67,0,0,1,3.82-3.82,11.15,11.15,0,0,1,3.74-.69c2.13-.1,2.76-0.12,8.14-0.12m0-3.63c-5.47,0-6.16,0-8.31.12a14.79,14.79,0,0,0-4.89.94A10.31,10.31,0,0,0,12.9,18.8,14.79,14.79,0,0,0,12,23.69c-0.1,2.15-.12,2.84-0.12,8.31s0,6.16.12,8.31a14.79,14.79,0,0,0,.94,4.89A10.31,10.31,0,0,0,18.8,51.1a14.79,14.79,0,0,0,4.89.94c2.15,0.1,2.84.12,8.31,0.12s6.16,0,8.31-.12a14.79,14.79,0,0,0,4.89-.94A10.31,10.31,0,0,0,51.1,45.2,14.79,14.79,0,0,0,52,40.31c0.1-2.15.12-2.84,0.12-8.31s0-6.16-.12-8.31a14.79,14.79,0,0,0-.94-4.89A10.31,10.31,0,0,0,45.2,12.9,14.79,14.79,0,0,0,40.31,12c-2.15-.1-2.84-0.12-8.31-0.12h0Z"></path>*/}
    {/*<path className="cls-1" d="M32,21.65A10.35,10.35,0,1,0,42.35,32,10.35,10.35,0,0,0,32,21.65Zm0,17.07A6.72,6.72,0,1,1,38.72,32,6.72,6.72,0,0,1,32,38.72Z"></path>*/}
    {/*<circle className="cls-1" cx="42.76" cy="21.24" r="2.42"></circle>*/}
{/*</svg>*/}
                                    {/*</span>*/}
                                    {/*</div>*/}
                                {/*</a>*/}
                                {/*<a href="" target="_blank" >*/}
                                    {/*<div className="c-primary-footer__content-social-item" ><span className="isvg pending" >*/}
                                        {/*<svg version="1.1" id="Arrow___-887664231" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 64 64" >*/}

{/*<g>*/}
    {/*<g>*/}
        {/*<path className="st0" d="M32,4c15.4,0,28,12.6,28,28S47.4,60,32,60S4,47.4,4,32S16.6,4,32,4 M32,0C14.3,0,0,14.3,0,32s14.3,32,32,32*/}
            {/*s32-14.3,32-32S49.7,0,32,0L32,0z"></path>*/}
    {/*</g>*/}
    {/*<g>*/}
        {/*<path className="st0" d="M21.9,32.8l-5.7,11.3c-0.4,0.8-0.2,1.7,0.4,2.3C17,46.8,17.5,47,18,47c0.3,0,0.6-0.1,0.9-0.2l11.3-5.7*/}
            {/*c0.2-0.1,0.4-0.2,0.5-0.4l21.5-21.5c-0.7-1.1-1.5-2.2-2.4-3.2L28.2,37.7l-5.7,2.8l2.8-5.7l21.6-21.6c-1.1-0.8-2.2-1.6-3.4-2.3*/}
            {/*L22.3,32.2C22.1,32.4,22,32.6,21.9,32.8z"></path>*/}
        {/*<path className="st0" d="M31.7,43.9c-0.3,0.3-0.6,0.5-1,0.6l-4.2,2h24.2c1-1,1.9-2,2.6-4H32.9L31.7,43.9z"></path>*/}
    {/*</g>*/}
{/*</g>*/}
{/*</svg>*/}
                                    {/*</span>*/}
                                    {/*</div>*/}
                                {/*</a>*/}
                                {/*<a href="" target="_blank" >*/}
                                    {/*<div className="c-primary-footer__content-social-item" ><span className="isvg pending" >*/}
                                        {/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">*/}
    {/*<defs>*/}

    {/*</defs>*/}
    {/*<title>.wtf</title>*/}
    {/*<path className="cls-1" d="M10.94,37.13a2.5,2.5,0,0,1-.18,1,2.72,2.72,0,0,1-.5.8,2.37,2.37,0,0,1-.74.55,2,2,0,0,1-.88.2,1.74,1.74,0,0,1-.73-.15,1.91,1.91,0,0,1-.59-.41,1.93,1.93,0,0,1-.4-.61,1.9,1.9,0,0,1-.15-.74A2.4,2.4,0,0,1,7,36.8,2.56,2.56,0,0,1,7.47,36a2.47,2.47,0,0,1,.77-.54,2.2,2.2,0,0,1,.94-.2,1.67,1.67,0,0,1,1.28.53A1.89,1.89,0,0,1,10.94,37.13Z"></path>*/}
    {/*<path className="cls-1" d="M15.87,31.47q.5-1.12.82-1.81a7.4,7.4,0,0,1,.59-1.07,1.5,1.5,0,0,1,.53-.51,1.67,1.67,0,0,1,1.73.27,1.3,1.3,0,0,1,.43,1,1.1,1.1,0,0,1,0,.29,2.15,2.15,0,0,1-.11.29l-2.74,6.51a1,1,0,0,0-.09.29A1.69,1.69,0,0,0,17,37a.48.48,0,0,0,.09.32.25.25,0,0,0,.18.11.9.9,0,0,0,.36-.08A2,2,0,0,0,18,37q.25-.2.61-.55l.9-.88a12.23,12.23,0,0,0,1-1.3q.53-.75,1-1.59T22.57,31q.44-.85.76-1.58a8.32,8.32,0,0,1,.44-.79,2.19,2.19,0,0,1,.38-.46,1,1,0,0,1,.38-.22A1.62,1.62,0,0,1,25,28q1,0,1,.86a1.5,1.5,0,0,0,0,.29,1.24,1.24,0,0,1-.09.37l-2.52,7.46a.36.36,0,0,0,.3.4h.1a2.2,2.2,0,0,0,.77-.29,6.08,6.08,0,0,0,1-.68,6.37,6.37,0,0,0,.9-1A3.79,3.79,0,0,0,27,34.29l.72-2.15a18.67,18.67,0,0,1,.77-2,6.74,6.74,0,0,1,.88-1.47,1.54,1.54,0,0,1,1.06-.62,1.15,1.15,0,0,1,.85.33,1.17,1.17,0,0,1,.33.88,6,6,0,0,1-.16,1.35A11.59,11.59,0,0,1,31,32.11a10.47,10.47,0,0,1-.65,1.41,11.91,11.91,0,0,1-.78,1.23.45.45,0,0,0,.47.34,6.91,6.91,0,0,0,1.17-.1,6.62,6.62,0,0,0,1.08-.29,9.64,9.64,0,0,0,1-.45q.52-.26,1.09-.59a.91.91,0,0,1,.53-.19.86.86,0,0,1,.84.87h0a1.14,1.14,0,0,1-.31.73,4.19,4.19,0,0,1-.83.73,8.19,8.19,0,0,1-1.17.66,13.8,13.8,0,0,1-1.33.54,11.3,11.3,0,0,1-1.3.37,5.14,5.14,0,0,1-1.09.14A3.87,3.87,0,0,1,27.89,37a9.06,9.06,0,0,1-2.35,2.07,4.86,4.86,0,0,1-2.48.71,2,2,0,0,1-1.67-.61,2.7,2.7,0,0,1-.51-1.76,10,10,0,0,1-2.27,1.81,4.5,4.5,0,0,1-2.17.59,3.56,3.56,0,0,1-1.17-.17,1.92,1.92,0,0,1-.77-.48,1.76,1.76,0,0,1-.43-.74,3.18,3.18,0,0,1-.12-.9,6.74,6.74,0,0,1,.23-1.67,15.74,15.74,0,0,1,.54-1.71q.31-.83.63-1.53Z"></path>*/}
    {/*<path className="cls-1" d="M38.3,30.38l-1.13,2.28-1,2.05a8.72,8.72,0,0,0-.38.9,2.67,2.67,0,0,0-.18.9.55.55,0,0,0,.11.35.42.42,0,0,0,.35.13,2.45,2.45,0,0,0,.86-.2A9.34,9.34,0,0,0,38,36.26q.59-.33,1.26-.76t1.3-.88q.64-.46,1.22-.92t1-.85a.88.88,0,0,1,.48-.18.54.54,0,0,1,.45.24,1,1,0,0,1,.18.62,1.82,1.82,0,0,1-.18.76,2.16,2.16,0,0,1-.64.76q-1,1-2.12,1.81a22,22,0,0,1-2.13,1.51,12,12,0,0,1-2.09,1,5.67,5.67,0,0,1-2,.39,3,3,0,0,1-1.08-.17,1.79,1.79,0,0,1-.7-.47,1.76,1.76,0,0,1-.38-.69,3.08,3.08,0,0,1-.11-.85,5.61,5.61,0,0,1,.28-1.68,11.36,11.36,0,0,1,.61-1.57l.95-2q.47-1,.93-1.89h-.68a2,2,0,0,1-1.24-.29.86.86,0,0,1-.34-.66,1,1,0,0,1,.11-.4,1.47,1.47,0,0,1,.29-.44,1.87,1.87,0,0,1,.49-.35,1.47,1.47,0,0,1,.66-.15H36.3l3.42-6.78a2.07,2.07,0,0,1,.8-.88,2,2,0,0,1,1-.27,1.61,1.61,0,0,1,.88.26.94.94,0,0,1,.4.86,1.55,1.55,0,0,1-.14.62q-.14.32-.36.74l-.35.66-.44.81-.48.9-.49.91-1.17,2.19h5.28a5.78,5.78,0,0,1,.71,0,1.65,1.65,0,0,1,.56.16.91.91,0,0,1,.36.33,1.08,1.08,0,0,1,.13.57,1.17,1.17,0,0,1-.06.35.77.77,0,0,1-.21.32,1.2,1.2,0,0,1-.42.24,2.64,2.64,0,0,1-.69.13Z"></path>*/}
    {/*<path className="cls-1" d="M53.15,30.19l-1.59,0-1.47,0H47.28q-.41.83-.93,1.93l-1.1,2.29q-.59,1.2-1.15,2.42T43,39.21l-.94,2-.68,1.4a3.37,3.37,0,0,1-.44.69,3.15,3.15,0,0,1-.57.54,2.6,2.6,0,0,1-.63.35,1.7,1.7,0,0,1-.59.12,1.27,1.27,0,0,1-.69-.19.67.67,0,0,1-.29-.62,1.55,1.55,0,0,1,.1-.49,6.3,6.3,0,0,1,.28-.66l5.66-12.12h-.86a2,2,0,0,1-1.21-.28.83.83,0,0,1-.32-.65,1,1,0,0,1,.4-.75,1.7,1.7,0,0,1,1.17-.36h1.91a23.34,23.34,0,0,1,2.3-3.81A13.28,13.28,0,0,1,49.9,22a7.08,7.08,0,0,1,2.17-1.21,6.62,6.62,0,0,1,2-.35,2.15,2.15,0,0,1,.86.18,2.05,2.05,0,0,1,.73.52,2.72,2.72,0,0,1,.51.9,3.77,3.77,0,0,1,.19,1.27,6.89,6.89,0,0,1-.19,1.54.78.78,0,0,1-.19.38,1.3,1.3,0,0,1-.32.25,1.38,1.38,0,0,1-.35.14,1.41,1.41,0,0,1-.29,0,1.2,1.2,0,0,1-.59-.15.51.51,0,0,1-.28-.48,2.91,2.91,0,0,1,0-.39,2.61,2.61,0,0,0,0-.36,1.49,1.49,0,0,0-.25-1A.84.84,0,0,0,53.3,23a2.24,2.24,0,0,0-1.17.44,7.62,7.62,0,0,0-1.36,1.17,13.19,13.19,0,0,0-1.32,1.66,10.77,10.77,0,0,0-1,1.91h3.76l.94,0a3.87,3.87,0,0,1,.77.1,1.07,1.07,0,0,1,.52.27.74.74,0,0,1,.18.54,1,1,0,0,1-.06.36.91.91,0,0,1-.22.33,1.2,1.2,0,0,1-.43.26A2.54,2.54,0,0,1,53.15,30.19Z"></path>*/}
    {/*<path className="cls-1" d="M32,4A28,28,0,1,1,4,32,28,28,0,0,1,32,4m0-4A32,32,0,1,0,64,32,32,32,0,0,0,32,0Z"></path>*/}
{/*</svg>*/}
                                    {/*</span>*/}
                                    {/*</div>*/}
                                {/*</a>*/}
                            </li>
                            <li >
                                <h6 className="type-subheadlines-buttons header" >Moscow</h6>
                                <h6 className="type-body-copy" ><a href="mailto:moscow@ideanix.ru" >moscow@ideanix.ru</a>
                                </h6>
                                <div className="c-primary-footer__content-phone" >
                                    <h6 className="type-body-copy" ><a
                                        href="tel:+79859928325" >+7 985 992 83 25</a></h6>
                                    <h6 className="type-body-copy" >+7 985 992 83 25</h6></div>
                                <h6 className="type-body-copy" >Moscow
                                    <br /> Bolshaya Savvinsky per. 9 </h6>
                                <div className="c-primary-footer__content-link" >
                                    <a href="https://goo.gl/maps/BAvQoXLB7T82" target="_blank" ><h6
                                    className="type-subheadlines-buttons" >See on map</h6></a>
                                    <hr className="c-hr c-hr--grey"  />
                                </div>
                            </li>
                            <li >
                                <h6 className="type-subheadlines-buttons header" >St. Petersburg</h6>
                                <h6 className="type-body-copy" ><a
                                    href="mailto:spb@ideanix.ru" >spb@ideanix.ru</a></h6>
                                <div className="c-primary-footer__content-phone" >
                                    <h6 className="type-body-copy" ><a
                                        href="tel:+79859928325" >+7 985 992 83 25</a></h6>
                                    <h6 className="type-body-copy" >+7 985 992 83 25</h6></div>
                                <h6 className="type-body-copy" > St. Petersburg
                                    <br /> Lermontovsky Ave, 13
                                </h6>
                                <div className="c-primary-footer__content-link" ><a href="https://goo.gl/maps/qGkck7hzcdT2" target="_blank" ><h6
                                    className="type-subheadlines-buttons" >See on map</h6></a>
                                    <hr className="c-hr c-hr--grey"  />
                                </div>
                            </li>
                        </ul>
                    </div>
                </footer>
            </div>

        )
    }
}



export default (PrimaryFooter);
