import React from 'react';
import { Link } from 'react-router-dom'
import {
    MalletImage,
    MallerOptimMp4, MallerOptimWebm,
} from '../../FlowMedia/index'

class HomeWork extends React.Component {


    render() {

        return (
            <div
                className="_ie-flex-min-height-fix">


                <section className="o-section-full home-work">
                    <div className="u-width-limiter">
                        <div className="c-section-video-fill-wrapper">
                            <video loop="loop" autoPlay muted poster={MalletImage}>
                                <source
                                    src={MallerOptimWebm} type="video/webm"/>
                                <source
                                    src={MallerOptimMp4} type="video/mp4"/>
                            </video>
                        </div>
                        <div className="home-work__content"><h6 className="type-subheadlines-buttons">Наша история успеха:</h6>
                            <h2
                                className="type-hero-headline">
                                <div>Кровь,<br/>Пот<br/>и Слезы
                                </div>
                            </h2>
                            <hr className="c-hr c-hr--yellow c-hr--large"/>
                            <Link to="/case-studies">
                                <h6 className="type-subheadlines-buttons">Наши работы</h6>
                            </Link>
                            <hr className="c-hr c-hr--black"/>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default (HomeWork);
