import React, { Component } from 'react';
import Navigator from '../../components/ru/Navigator'
import CaseStudies from '../../components/ru/CaseStudies/CaseStudies'
//import Meta from '../Meta';

class App extends Component {
    render() {
        return (
            <div className="App">
                <Navigator/>
                <CaseStudies/>
            </div>
        );
    }
}

export default App;
