import React from 'react';

import PrimaryFooterDark from '../../../components/en/PrimaryFooterDark'
import MadeBy from '../../../components/en/Works/MadeBy'
import Navigator from '../../../components/en/Navigator'
//import Meta from '../../Meta';

class MadeByC extends React.Component {


    render() {

        return (
            <div className='page-contact'>
                <Navigator/>
                <MadeBy/>
                <PrimaryFooterDark/>
            </div>
        )
    }
}

export default (MadeByC);
