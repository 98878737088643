import React from 'react';
import {Link} from 'react-router-dom';


class FullLanding extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav primark" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper primark__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >Primark</h6>
                                    <h2 className="type-bloc-headline" >A live Christmas</h2>
                                    <hr className="c-hr c-hr--primark c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >Putting live video at the core of the Christmas campaign</h3>
                                </div>
                            </header>
                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Primark has a hugely loyal following both on and offline.</h3>
                            <hr className="c-hr c-hr--primark c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >Our task was to engage this audience during the Christmas period, cutting through the noise and not competing against the usual suspects for TV spots. So, we took Primark “live” to their communities online with a series of Facebook live shows; showcasing ranges, hosting competitions and bringing the morning TV model to the world of Live Facebook productions for the first time.</p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited u-no-padding-below-desktop" >
                            <figure className="case-study-figure" >
                                <div >
                                    <video autoPlay muted loop  poster="/static/img/case-studies/primark/video-thumbnail.jpg" className="u-image-restraints js-controls-video" >
                                        <source src="/static/vids/case-studies/primark.webm" type="video/webm" />
                                        <source src="/static/vids/case-studies/primark.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >A new medium for a much-loved format.</h3>
                            <hr className="c-hr c-hr--primark c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >We wanted to bring the audience in and create a live dialogue and a two-way, immersive, live TV experience – the likes of which we all remember as children from Saturday morning TV. From photography competitions and outfit price quizzes, to voting mechanics and on-set challenges, we brought the consumer into our world and just had FUN! Over the course of the day, we hosted five 30min episodes staggered from morning until evening to gain maximum exposure with our varied audiences. Hosted by two core presenters and supported by two others, this was as much a production as any TV programme and commanded matching levels of creative, planning and delivery.</p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <img src="/static/img/case-studies/primark/devices.jpg" alt="" className="u-image-full-width case-study-reveal-large-image" />
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <p className="type-body-copy case-study-limited-paragraph" >Executed on a tight budget, we built the campaign to deliver the best possible results for the money, to ensure that we can build “Live” as an ownable area for Primark moving forwards and to ensure we didn’t sacrifice on quality by overstretching. We’re already looking forward to next Christmas…</p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <p className="type-body-copy u-text-center" >The Results</p>
                            <h3 className="type-block-sub-headline2" >Over the course of the day, our five 30min “shows” reached a total of 3.7 million users organically and accrued over 320,000 unique video views. As for the audience interactions, we generated a whopping 5000+ in-stream comments and interactions all in a day’s work.</h3>
                        </div>
                    </section>
                </div>
                <div className="case-studies-reel" >
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek" to="/en/case-studies/jacobs-creek" >
                        <h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons" >Website Redesign</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/en/case-studies/made-by" >
                        <h4 className="type-block-sub-headline" >Made By</h4>
                        <h6 className="type-subheadlines-buttons" >Campaign</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/en/case-studies/rjsmith" >
                        <h4 className="type-block-sub-headline" >RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons" >Branding / Website</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/en/case-studies" >
                        <h4 className="type-block-sub-headline" >Blood, Sweat &amp; Tears</h4>
                        <h6 className="type-subheadlines-buttons" >See all our work.</h6>
                    </Link>
                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (FullLanding);
