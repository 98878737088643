import React from 'react';
import { Link } from 'react-router-dom'
import {
    CrowImage,
    CrownOptimWebm, CrownOptimMp4,
} from '../../FlowMedia/index'


class HomeLanding extends React.Component {


    render() {

        return (
            <div
                className="_ie-flex-min-height-fix">

                <section
                    className="o-section-full o-section--below-nav home-landing">
                    <div className="u-width-limiter" >
                        <div
                            className="c-section-video-fill-wrapper">
                            <video autoPlay loop="loop" muted
                                   poster={CrowImage}
                                   data-load-time-perc="auto"
                                >
                                <source
                                    src={CrownOptimWebm} type="video/webm"/>
                                <source
                                    src={CrownOptimMp4} type="video/mp4"/>
                            </video>
                        </div>
                        <header>
                            <h6 className="type-subheadlines-buttons">
                                <div>Мы  Ideanix.</div>
                            </h6>
                            <h2
                                className="type-intro-headline">
                                <div>Мы<br/>вдыхаем жизнь<br/>в идеи.<br/></div>
                            </h2>
                            <hr
                                className="c-hr c-hr--yellow c-hr--large"/>
                            <Link to="/about"><h6
                                className="type-subheadlines-buttons special-about-link">О нас</h6>
                            </Link>
                            <hr
                                className="c-hr c-hr--grey"/>
                        </header>
                    </div>
                </section>

            </div>
        )
    }
}

export default (HomeLanding);
