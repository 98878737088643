import React from 'react';

import PrimaryFooterDark from '../../../components/ru/PrimaryFooterDark'
import MadeBy from '../../../components/ru/Works/MadeBy'
import Navigator from '../../../components/ru/Navigator'

//import Meta from '../../Meta';

class MadeByC extends React.Component {


    render() {

        return (
            <div className='page-contact'>
                <Navigator/>
                <MadeBy/>
                <PrimaryFooterDark/>
            </div>
        )
    }
}

export default (MadeByC);
