import React from 'react';
import {Link} from 'react-router-dom';


class JacobsCreek extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav jacobs-creek" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper jacobs-creek__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >Jacob&#x27;s Creek</h6>
                                    <h2 className="type-bloc-headline" >The new jacobscreek.com</h2>
                                    <hr className="c-hr c-hr--jc c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >Changing the perception
                                        of a global wine icon.</h3></div>
                            </header>

                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Jacob’s Creek suffered from an
                                image problem. It was perceived as a mass-produced wine “my mum used to drink”. So we
                                undertook a global project to re-launch the brand in the digital space and make people
                                care again.</h3>
                            <hr className="c-hr c-hr--jc c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited" >
                            <figure className="case-study-figure" >
                                <div >
                                    <video controls="" className="u-image-restraints js-controls-video" >
                                        <source src="/assets/vids/case-studies/jacobs-creek.webm" type="video/webm" />
                                        <source src="/assets/vids/case-studies/jacobs-creek.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >A digital re-launch that put the
                                Brand’s people, place and passions at the heart of the brand.</h3>
                            <hr className="c-hr c-hr--jc c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >We wanted to
                                show people that despite Jacob’s Creek’s size and history, they are still, at their
                                hearts a craft brand. The wine they make is grown, picked, pressed and aged in
                                Australia, by real people with real passion.</p>
                        </div>
                    </section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/jacobs-creek/pages1.jpg" className="u-image-restraints" /></section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >The work included a complete new
                                design and build, photography, video and copywriting.</h3>
                            <hr className="c-hr c-hr--jc c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/jacobs-creek/pages2.jpg" className="u-image-restraints" /></section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/jacobs-creek/pages3.jpg" className="u-image-restraints" /></section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >A bespoke CMS to fit bespoke
                                business needs.</h3>
                            <hr className="c-hr c-hr--jc c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >Instead of
                                trying to wrap the intricate needs of a business which sells over 150 different SKUs,
                                across 40 markets in 5 different languages into an off the shelf solution, we built a
                                custom CMS that ensured the brand’s design integrity was maintained whilst giving
                                markets and global brand owners the right level of control.</p>
                        </div>
                    </section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/jacobs-creek/cms.jpg" className="u-image-restraints" /></section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >The Our Table content
                                platform</h3>
                            <hr className="c-hr c-hr--jc c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >To extend the
                                website’s content offering, we created Our Table, a content and eCRM hub that allows the
                                brand to share their knowledge, and passions to their global consumer base.</p>
                        </div>
                    </section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/jacobs-creek/our-table.png" className="u-image-restraints" /></section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/jacobs-creek/our-table-pages.jpg" className="u-image-restraints" /></section>

                </div>
                <div className=".case-studies-reel" >

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--tube-strike"
                    to="/en/case-studies/tube-strike" ><h4 className="type-block-sub-headline" >F*cking Tube Strike</h4>
                    <h6
                        className="type-subheadlines-buttons" >Reactive</h6></Link>


                    <Link
                    className="case-studies-reel__item case-studies-reel__item--made-by" to="/en/case-studies/made-by" ><h4 className="type-block-sub-headline" >Made By</h4><h6
                    className="type-subheadlines-buttons" >Campaign</h6></Link>


                    <Link
                    className="case-studies-reel__item case-studies-reel__item--popchips" to="/en/case-studies/popchips" ><h4 className="type-block-sub-headline" >Pop Chips</h4><h6
                    className="type-subheadlines-buttons" >Social Media</h6></Link>

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--more" to="/en/case-studies" ><h4 className="type-block-sub-headline" >Blood, Sweat &amp; Tears</h4><h6
                    className="type-subheadlines-buttons" >See all our work.</h6></Link>


                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (JacobsCreek);
