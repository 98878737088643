import React from 'react';
import AboutLanding from './AboutLanding'
import AboutWhatWeDo from './AboutWhatWeDo'
import AboutHowWeDoIt from './AboutHowWeDoIt'
import AboutWeAre from './AboutWeAre'
import AboutExcited from './AboutExcited'
class FullLanding extends React.Component {


    render() {

        return (
            <div className='page-about'>
                <AboutLanding/>
                <AboutWhatWeDo/>
                <AboutHowWeDoIt/>
                <AboutWeAre/>
                <AboutExcited/>
            </div>
        )
    }
}

export default (FullLanding);
