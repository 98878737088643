import React, { Component } from 'react';
import Preloader from '../../../components/ru/Preloader'
import Navigator from '../../../components/ru/Navigator'
import CPJ from '../../../components/ru/Works/CPJ'
import FooterWhite from '../../../components/ru/FooterWhite'
//import Meta from '../../Meta';

class About extends Component {
    render() {
        return (
            <div className="App">
                <Navigator/>
                <CPJ/>
                <FooterWhite/>
            </div>
        );
    }
}

export default About;
