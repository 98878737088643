import React from 'react';
import {Link} from 'react-router-dom';


class BrancottEstate extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div
                    className="_ie-flex-min-height-fix" >
                    <section
                        className="o-section-full o-section--below-nav brancott-estate" >
                        <div
                            className="brancott-estate__bg-details" ></div>
                        <div
                            className="brancott-estate__feather brancott-estate__feather--a" ><img
                            src="/static/img/case-studies/brancott-estate/feather.png" alt="" /></div>
                        <div
                            className="brancott-estate__feather brancott-estate__feather--b" ><img
                            src="/static/img/case-studies/brancott-estate/feather.png" alt="" /></div>
                        <div
                            className="brancott-estate__feather brancott-estate__feather--c" ><img
                            src="/static/img/case-studies/brancott-estate/feather.png" alt="" /></div>
                        <div
                            className="u-width-limiter" >
                            <header
                                className="case-study-heading-wrapper brancott-estate__heading-wrapper" >
                                <div
                                    className="case-study-heading" ><h6 className="type-subheadlines-buttons" >Brancott
                                    Estate</h6>
                                    <h2
                                        className="type-bloc-headline" >
                                        <div >Brancott Estate Pioneers</div>
                                    </h2>
                                    <hr
                                        className="c-hr c-hr--be c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >Pioneering digital storytelling</h3><a
                                        href="http://pioneers.brancottestate.com/" target="_blank" ><h6
                                        className="type-subheadlines-buttons case-study-heading__link" >pioneers.brancottestate.com</h6></a>
                                    <hr className="c-hr c-hr--be" />
                                </div>
                            </header>

                        </div>
                    </section>
                </div>
                <div
                    className="case-study-sections-wrapper brancott-estate" >
                    <section className="o-section-std" >
                        <div
                            className="u-width-limiter u-width-limiter--low-limited" ><h3
                            className="type-block-sub-headline2" >Brancott Estate has a compelling story we
                            wanted to tell; they pioneered Marlborough Sauvignon Blanc by planting the first vines in
                            Marlborough New Zealand.</h3>
                            <hr
                                className="c-hr c-hr--be c-hr--large" />
                            <p
                                className="type-body-copy case-study-limited-paragraph" >However we had a
                                problem. When we asked consumers ‘do you want to hear our story?’ they overwhelmingly
                                said no. But, when we took the time to tell them our story, they loved it. From this
                                insight we decided to not change the story, but simply how we told it.</p></div>
                    </section>
                    <section
                        className="o-section-std" >
                        <div
                            className="u-width-limiter u-width-limiter--high-limited u-no-padding-below-desktop" >
                            <figure
                                className="case-study-figure" >
                                <div >
                                    <video  loop="loop" autoPlay muted
                                           className="u-image-restraints js-controls-video" >
                                        <source
                                            src="/static/vids/case-studies/brancott-estate.webm" type="video/webm" />
                                        <source
                                            src="/static/vids/case-studies/brancott-estate.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div
                            className="u-width-limiter u-width-limiter--low-limited" ><h3
                            className="type-block-sub-headline2" >By using compelling design and the latest
                            HTML5 techniques we brought the story to life in interactive digital glory.</h3>
                            <hr
                                className="c-hr c-hr--be c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div
                            className="u-width-limiter u-width-limiter--low-limited" ><p
                            className="type-body-copy u-text-center" >The Results</p>
                            <div
                                className="case-study-impressions" >
                                <div className="case-study-impressions__group" ><h2 className="type-bloc-headline" >4x</h2><p
                                    className="type-body-copy" >Expected site traffic</p></div>
                                <div
                                    className="case-study-impressions__group" ><h2
                                    className="type-bloc-headline" >2.5 million</h2><p
                                    className="type-body-copy" >Estimated Organic Reach</p></div>
                                <div
                                    className="case-study-impressions__group" ><h2
                                    className="type-bloc-headline" >+100</h2><p
                                    className="type-body-copy" >Online PR mentions</p></div>
                            </div>
                        </div>
                    </section>

                </div>
                <div
                    className=".case-studies-reel" >
                    <Link
                    className="case-studies-reel__item case-studies-reel__item--tube-strike"
                    to="/en/case-studies/tube-strike" ><h4 className="type-block-sub-headline" >F*cking Tube Strike</h4>
                    <h6
                        className="type-subheadlines-buttons" >Reactive</h6></Link>

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--jacobs-creek"
                    to="/en/case-studies/jacobs-creek" ><h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4><h6
                    className="type-subheadlines-buttons" >Website Redesign</h6></Link>

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--popchips" to="/en/case-studies/popchips" ><h4 className="type-block-sub-headline" >Pop Chips</h4><h6
                    className="type-subheadlines-buttons" >Social Media</h6></Link>

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--more" to="/en/case-studies" ><h4 className="type-block-sub-headline" >Blood, Sweat &amp; Tears</h4><h6
                    className="type-subheadlines-buttons" >See all our work.</h6></Link>


                </div>
                <div
                    className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (BrancottEstate);
