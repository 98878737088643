import React from 'react';
import PageCaseStudyLandingHero from './PageCaseStudyLandingHero'
import CaseStudyLandingDivide from './CaseStudyLandingDivide'
import CaseStudyLandingHalfBlocksWrapper from './CaseStudyLandingHalfBlocksWrapper'
import PrimaryFooter from '../PrimaryFooter'


class CaseStudies extends React.Component {


    render() {

        return (
            <div className='page-case-study-landing'>
                <PageCaseStudyLandingHero/>
                <CaseStudyLandingDivide/>
                <CaseStudyLandingHalfBlocksWrapper/>
                <PrimaryFooter/>
            </div>
        )
    }
}

export default (CaseStudies);
