import React, { Component } from 'react';
import Preloader from '../../components/en/Preloader'
import Navigator from '../../components/en/Navigator'
import Jobs from '../../components/en/Jobs'
import FooterOr from '../../components/en/FooterOr'
//import Meta from '../Meta';

class About extends Component {
    render() {
        return (
            <div className="App">
                <Navigator/>
                <Jobs/>
                <FooterOr/>
            </div>
        );
    }
}

export default About;
