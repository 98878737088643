import React from 'react';


class AboutHowWeDoIt extends React.Component {


    render() {

        return (
            <div className="_ie-flex-min-height-fix" >
                <section className="o-section-full about-how-we-do-it" >
                    <div className="u-width-limiter" >
                        <div className="about-how-we-do-it__content" >
                            <h6 className="type-subheadlines-buttons" >How we do it</h6>
                            <h2 className="type-bloc-headline" ><div >We put the<br/>consumer first,<br/>ignore category<br/>conventions</div></h2>
                            <h6 className="type-subheadlines-buttons" >and compel new audiences to love you.</h6>
                            <h6 className="type-block-sub-headline2" ><div >Our proprietary Fame and Glory model is designed to change the direction of brands by finding new conversations and new consumers to buy from you. We lead with strategy, disrupt category conventions and think ‘consumer first.’ We find the one thing your brand can authentically be famous for and then find new creative ways to bring that to life over and over and over again so your brand becomes known for it the world over.</div></h6>
                            <hr className="c-hr c-hr--black c-hr--large"  />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default (AboutHowWeDoIt);
