import React from 'react';


class Preloader extends React.Component {


    render() {

        return (
            <div className="c-preloader" ></div>
        )
    }
}



export default (Preloader);
