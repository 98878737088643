import React from 'react';
import {Link} from 'react-router-dom';


class FullLanding extends React.Component {


    render() {

        return (
            <div className="page-case-study">
                <div className="_ie-flex-min-height-fix">
                    <section className="o-section-full o-section--below-nav beefeater">
                        <div className="u-width-limiter">
                            <header className="case-study-heading-wrapper beefeater__heading-wrapper">
                                <div className="case-study-heading">
                                    <h6 className="type-subheadlines-buttons">Beefeater Gin: Global Relaunch</h6>
                                    <h2 className="type-bloc-headline">
                                        <div>Returning Beefeater to Fame and Glory</div>
                                    </h2>
                                    <hr className="c-hr c-hr--white c-hr--large"/>
                                </div>
                            </header>

                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper">
                    <div className="case-study-sections-wrapper--beefeater-red">
                        <section className="o-section-std no-padding-top">
                            <div className="u-width-limiter u-width-limiter--low-limited">
                                <h3 className="type-block-sub-headline2">Right now, there is a global gin boom
                                    happening, the category is in huge growth, yet one of the most historically iconic
                                    brands, Beefeater, wasn’t getting their fair share of it.</h3>
                                <hr className="c-hr c-hr--white"/>
                                <p className="type-body-copy case-study-limited-paragraph">With so many new gins
                                    launching, consumers felt Beefeater had become a tired brand. Our challenge was to
                                    find a new audience to align with and relaunch the brand globally to inspire
                                    them.</p>
                                <h6 className="type-subheadlines-buttons">Beefeater Gin: Global Relaunch</h6>
                                <p className="type-body-copy case-study-limited-paragraph">Instead of talking up
                                    fantastical stories of Victorian times or magical botanicals (a sentiment that
                                    dominates almost every other brand in the the entire category) we set ourselves
                                    apart. We focused on the new generation of gin drinkers, those who are young, urban
                                    and real.</p>
                            </div>
                        </section>
                        <section className="o-section-std">
                            <div className="u-width-limiter u-width-limiter--high-limited u-no-padding-below-desktop">
                                <figure className="case-study-figure">
                                    <div>
                                        <video loop="loop" autoPlay muted
                                               className="u-image-restraints js-controls-video">
                                            <source src="/static/vids/case-studies/beefeater.mp4" type="video/mp4"/>
                                        </video>
                                    </div>
                                </figure>
                            </div>
                        </section>
                    </div>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited">
                            <h3 className="type-block-sub-headline2">New brand world</h3>
                            <hr className="c-hr c-hr--black"/>
                            <p className="type-body-copy">We completely redesigned Beefeater’s brand world and created
                                an urban and energetic design language that could be brought to life across all touch
                                points - including a whole catalogue of new swag we created.</p>
                            <img src="/static/img/case-studies/beefeater/flashing-lines.gif"
                                 className="u-image-restraints"/>
                            <div className="o-image-grid-container">
                                <div className="c-image-grid-row">
                                    <div className="two-col">
                                        <img src="/static/img/case-studies/beefeater/beefeater-bags.jpg"
                                             className="u-image-restraints"/>
                                        <img src="/static/img/case-studies/beefeater/beefeater-models.jpg"
                                             className="u-image-restraints"/>
                                    </div>
                                    <div className="large">
                                        <img src="/static/img/case-studies/beefeater/beefeater-board.jpg"
                                             className="u-image-restraints"/>
                                    </div>
                                </div>
                                <div className="c-image-grid-row image-grid--reversed">
                                    <div className="two-col">
                                        <div>
                                            <video autoPlay loop muted className="u-image-restraints">
                                                <source src="/static/vids/case-studies/beefeater-2.mp4"
                                                        type="video/mp4"/>
                                            </video>
                                        </div>
                                        <img src="/static/img/case-studies/beefeater/iphone-cases.jpg"
                                             className="u-image-restraints"/>
                                    </div>
                                    <div className="large">
                                        <img src="/static/img/case-studies/beefeater/brick-wall.jpg"
                                             className="u-image-restraints"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="case-study-sections-wrapper--beefeater-red">
                        <section className="o-section-std">
                            <div className="u-width-limiter u-width-limiter--low-limited">
                                <h3 className="type-block-sub-headline2">We were looking for a
                                    partner to help us translate creatively what being from London means in the 21st
                                    Century. With Impero we have found a team that is 100% aligned with our vision and
                                    they have been since day one.</h3>
                                <hr className="c-hr c-hr--white"/>
                                <p className="type-subheadlines-buttons quote">- Eric Sampers, Brand
                                    Director Beefeater Gin.</p>
                            </div>
                        </section>
                    </div>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited">
                            <h3 className="type-block-sub-headline2">Taking it to the streets</h3>
                            <hr className="c-hr c-hr--black"/>
                            <p className="type-body-copy">Across key markets (UK, Spain, Belgium and
                                others) we launched a mixed out of home and digital campaign.</p>
                            <div className="o-image-grid-container">
                                <div className="c-image-grid-row">
                                    <div className="grid-item">
                                        <img src="/static//img/case-studies/beefeater/section-4-grid-1.jpg"
                                             className="u-image-restraints"/>
                                    </div>
                                    <div className="grid-item">
                                        <img src="/static/img/case-studies/beefeater/section-4-grid-2.jpg"
                                             className="u-image-restraints"/>
                                    </div>
                                </div>
                                <div className="c-image-grid-row">
                                    <div className="grid-item">
                                        <img src="/static/img/case-studies/beefeater/section-4-grid-3.jpg"
                                             className="u-image-restraints"/>
                                    </div>
                                    <div className="grid-item">
                                        <img src="/static/img/case-studies/beefeater/section-4-grid-4.jpg"
                                             className="u-image-restraints"/>
                                    </div>
                                </div>
                                <div >
                                    <video autoPlay loop muted className="u-image-restraints">
                                        <source src="/static/vids/case-studies/beefeater-3.mp4" type="video/mp4"/>
                                    </video>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="case-study-sections-wrapper--beefeater-red">
                        <section className="o-section-std">
                            <div className="u-width-limiter u-width-limiter--low-limited">
                                <h3 className="type-block-sub-headline2">With our out-of-home
                                    campaign, we wanted to feel as though we’re a part of the fabric of the city.</h3>
                                <hr className="c-hr c-hr--white"/>
                                <p className="type-subheadlines-buttons quote">- Adam Boita, Brand
                                    Director, Pernod Ricard UK</p>
                            </div>
                        </section>
                    </div>
                    <section className="o-section-std">
                        <div className="u-width-limiter u-width-limiter--low-limited">
                            <h3 className="type-block-sub-headline2">Creating an own-able serve</h3>
                            <hr className="c-hr c-hr--black"/>
                            <p className="type-body-copy">Establishing an ‘ask for it by name’ moment
                                in the on-trade and in home, we created a new, easy to make signature G&amp;T - the
                                B&amp;T.</p>
                            <div className="o-image-grid-container">
                                <div className="c-image-grid-row">
                                    <div className="grid-item">
                                        <img src="/static/img/case-studies/beefeater/section-6-grid-1.jpg"
                                             className="u-image-restraints"/>
                                    </div>
                                </div>
                                <div className="c-image-grid-row">
                                    <div className="grid-item">
                                        <div>
                                            <video autoPlay loop muted className="u-image-restraints">
                                                <source src="/static/vids/case-studies/beefeater-4.mp4" type="video/mp4"/>
                                            </video>
                                        </div>
                                    </div>
                                    <div className="grid-item grid-80"></div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <div className=".case-studies-reel">
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/en/case-studies/rjsmith">
                        <h4 className="type-block-sub-headline">RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons">Branding / Website</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--tube-strike"
                          to="/en/case-studies/tube-strike">
                        <h4 className="type-block-sub-headline">F*cking Tube Strike</h4>
                        <h6 className="type-subheadlines-buttons">Reactive</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek"
                          to="/en/case-studies/jacobs-creek">
                        <h4 className="type-block-sub-headline">Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons">Website Redesign</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/en/case-studies">
                        <h4 className="type-block-sub-headline">Blood, Sweat &amp; Tears</h4>
                        <h6 className="type-subheadlines-buttons">See all our work.</h6>
                    </Link>
                </div>
                <div className="u-footer-spacer"></div>
            </div>
        )
    }
}

export default (FullLanding);
