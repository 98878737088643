import React from 'react';
import {Link} from 'react-router-dom';


class MadeBy extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav made-by-landing" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper made-by-landing__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >Jacob&#x27;s Creek</h6>
                                    <h2 className="type-bloc-headline" >
                                        <div >‘Made By’<br/>campaign with<br/>Novak Djokovic</div>
                                    </h2>
                                    <hr className="c-hr c-hr--red c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >A global digital campaign
                                        to land Jacob’s Creek’s new brand proposition.</h3></div>
                            </header>
                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Working with our friends at C+P
                                and OGB, we embarked on a global digital campaign to land Jacob’s Creek’s new ‘Made By’
                                brand story, with Novak Djokovic as our hero.</h3>
                            <hr className="c-hr c-hr--red c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >At the heart of
                                the campaign were three films, shot by C+P; our job was to put them in front of the
                                right consumers at the right time.</p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited" >
                            <a href="https://www.youtube.com/watch?v=VasWcH5vCTs" target="_blank" >
                                <figure className="case-study-figure" >
                                    <div className="made-by-loop-video" >
                                        <video loop="loop" autoPlay autoplay="" className="u-image-restraints" >
                                            <source src="/static/vids/case-studies/made-by-loop.webm" type="video/webm" />
                                            <source src="/static/vids/case-studies/made-by-loop.mp4" type="video/mp4" />
                                        </video>
                                        <img src="/static/img/case-studies/made-by/made-by-loop-mob.jpg"
                                             className="u-image-restraints" /></div>
                                </figure>
                            </a>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Ensuring a consumer-first
                                approach, we took the Jacob’s Creek ‘Made By Australia’ proposition, and Novak
                                Djokovic’s ‘Made by Determination’ and allowed our consumers to be the hero of the story
                                via an award-winning digital design experience called ‘Discover what you&#x27;re made
                                by’.</h3>
                            <hr className="c-hr c-hr--red c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" ><img
                        src="/static/img/case-studies/made-by/devices.png"
                        alt="Made By website preview in several devices."
                        className="u-image-full-width case-study-reveal-large-image" /></section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >An algorithm was developed using
                                Myers Briggs methodology to unearth the ‘inner you’ in an intriguing and fulfilling
                                way.</h3>
                            <hr className="c-hr c-hr--red c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited" >
                            <figure className="case-study-figure" >
                                <div >
                                    <video loop="loop" autoPlay controls=""
                                           poster="/static/vids/case-studies/made-by.jpg"
                                           className="u-image-restraints js-controls-video" >
                                        <source src="/static/vids/case-studies/made-by.webm" type="video/webm" />
                                        <source src="/static/vids/case-studies/made-by.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >We’d love to tell you all the
                                results, because they were amazing, but they’re confidential. However, we can tell you
                                that we achieved:</h3>
                            <hr className="c-hr c-hr--red c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <p className="type-body-copy u-text-center" >The Results</p>
                            <div className="case-study-impressions" >
                                <div className="case-study-impressions__group" >
                                    <h2 className="type-bloc-headline" >210%</h2>
                                    <p className="type-body-copy" >in Exceeded reach KPI</p>
                                </div>
                                <div className="case-study-impressions__group" >
                                    <h2 className="type-bloc-headline" >220%</h2>
                                    <p className="type-body-copy" >in Exceeded film view KPI</p>
                                </div>
                                <div className="case-study-impressions__group" >
                                    <h2 className="type-bloc-headline" >364%</h2>
                                    <p className="type-body-copy" >in Lifted web traffic</p>
                                </div>
                                <div className="case-study-impressions__group" >
                                    <h2 className="type-bloc-headline" >170%</h2>
                                    <p className="type-body-copy" >in Exceeded engagement KPI</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="case-studies-reel" >
                    <Link
                    className="case-studies-reel__item case-studies-reel__item--popchips" to="/en/case-studies/popchips" ><h4 className="type-block-sub-headline" >Pop Chips</h4><h6
                    className="type-subheadlines-buttons" >Social Media</h6></Link>

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--tube-strike"
                    to="/en/case-studies/tube-strike" ><h4 className="type-block-sub-headline" >F*cking Tube Strike</h4>
                    <h6
                        className="type-subheadlines-buttons" >Reactive</h6></Link>

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/en/case-studies/rjsmith" ><h4 className="type-block-sub-headline" >RJ Smith</h4><h6
                    className="type-subheadlines-buttons" >Branding / Website</h6></Link>

                    <Link
                    className="case-studies-reel__item case-studies-reel__item--more" to="/en/case-studies" ><h4 className="type-block-sub-headline" >Blood, Sweat &amp; Tears</h4><h6
                    className="type-subheadlines-buttons" >See all our work.</h6></Link>


                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (MadeBy);
