import React from 'react';
import {Link} from 'react-router-dom';


class Whatson extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav whatson" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper whatson__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >Scopus & Web of Science CRM</h6>
                                    <h2 className="type-bloc-headline" >
                                        <div >CRM система для учета публикаций сотрудников
                                        </div>
                                    </h2>
                                    <hr className="c-hr c-hr--whatson c-hr--large" />
                                    {/*<h3 className="type-block-sub-headline2" >*/}
                                        {/*Запуск Чат Бота в Facebook Messenger по мотивам известного мультика*/}
                                    {/*</h3>*/}
                                    <a className="whatson__chat-btn" href="/contact" data-reactid="102">Давайте поговорим о создании CRM системы для Вас</a>

                                </div>
                            </header>
                        </div>
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2 whatson" >
                                В нашу компанию обратился один из ведущих вузов России с задачей создания системы автоматизированного учета публикаций сотрудников, аспиронтов и студентов ВУЗа
                            </h3>
                            <hr className="c-hr c-hr--whatson c-hr--large" />
                        </div>
                        <div className="whatson-o-animations-container one js-ticket-container" >

                            {/*<img*/}
                            {/*src="/static/img/case-studies/scopus/bib.jpeg" className="u-image-restraints" />*/}

                            {/*<img src="/static/img/case-studies/whatson/robot-ticket-2.png"*/}
                                                                                                                       {/*className="u-image-restraints js-anim-ticket-2" />*/}

                            {/*<img*/}
                            {/*src="/static/img/case-studies/whatson/robot-ticket-1.png"*/}
                            {/*className="u-image-restraints js-anim-ticket-1" />*/}
                            <img
                            src="/static/img/case-studies/scopus/scopus.png" className="u-image-restraints" />

                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                В рамках этого проетка мы создали полноценную CRM систему учета публикаций сотрудников высшего учебного заведения.
                                Функции системы:
                                <ul>
                                    <li>Система авторизации и разделения прав пользователей</li>
                                    <li>Система автоматизированной выгрузки данных из Scopus & Web of Science</li>
                                    <li>Идентификация авторов, сотрудников, студентов</li>
                                    <li>Графические отчеты</li>
                                    <li>Экспорт данных в Excel</li>
                                    <li>Интерфейс администратора для управления системой</li>
                                    <li>Рассылка отчетов системы</li>
                                </ul>
                            </h3>
                            <hr className="c-hr c-hr--whatson c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                Основной экран рядового пользователя содержит графический интерфейс, а так же инфорграфику по сосновным отчетам системы.
                            </h3>
                            <hr className="c-hr c-hr--whatson c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std whatson__devices" >
                        <img
                        src="/static/img/case-studies/scopus/01.jpg" alt="Scopus & Web of Science CRM"
                        className="u-image-full-width case-study-reveal-large-image u-tablet-and-up-only" />

                        <img src="/static/img/case-studies/scopus/01.jpg"
                                                                                                                 alt="Scopus & Web of Science CRM"
                                                                                                                 className="u-image-full-width case-study-reveal-large-image u-mobile-only" />
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >
                                Основной экран управления правами пользователей позволяет быстро создавать/удалять или просматривать инфромацию о пользователеях состояших в системе.
                            </h3>
                            <hr className="c-hr c-hr--whatson c-hr--large"/>
                        </div>
                    </section>
                    <section className="o-section-std whatson__devices" ><img
                        src="/static/img/case-studies/scopus/admin.jpg" alt="Scopus & Web of Science CRM | admin"
                        className="u-image-full-width case-study-reveal-large-image u-tablet-and-up-only" /><img src="/static/img/case-studies/scopus/admin.jpg"
                                                                                                                 alt="Scopus & Web of Science CRM | admin"
                                                                                                                 className="u-image-full-width case-study-reveal-large-image u-mobile-only" />
                        <Link className="whatson__chat-btn" to="/contact" >
                            Давайте поговорим о создании CRM системы для Вас
                        </Link>
                    </section>

                </div>
                <div className=".case-studies-reel" >
                    <Link className="case-studies-reel__item case-studies-reel__item--jacobs-creek" to="/case-studies/jacobs-creek" >
                        <h4 className="type-block-sub-headline" >Jacob&#x27;s Creek</h4>
                        <h6 className="type-subheadlines-buttons" >Обновление сайта Jacob's Creek</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/case-studies/made-by" >
                        <h4 className="type-block-sub-headline" >Made By</h4>
                        <h6 className="type-subheadlines-buttons" >Создание истории бренда «Made By»</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/case-studies/rjsmith" >
                        <h4 className="type-block-sub-headline" >RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons" >Создание бренда</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/case-studies" >
                        <h4 className="type-block-sub-headline" >Кровь, Пот &amp; Слезы</h4>
                        <h6 className="type-subheadlines-buttons" >Посмотреть все наши работы.</h6>
                    </Link>
                </div>
            </div>
        )
    }
}

export default (Whatson);
