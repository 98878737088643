import React from 'react';
import {Link} from 'react-router-dom';


class FullLanding extends React.Component {


    render() {

        return (
            <div className="page-case-study" >
                <div className="_ie-flex-min-height-fix" >
                    <section className="o-section-full o-section--below-nav seven-up" >
                        <div className="u-width-limiter" >
                            <header className="case-study-heading-wrapper brancott-estate__heading-wrapper" >
                                <div className="case-study-heading" >
                                    <h6 className="type-subheadlines-buttons" >7 Up</h6>
                                    <h2 className="type-bloc-headline" >
                                        <div >7up verses the heat</div>
                                    </h2>
                                    <hr className="c-hr c-hr--seven-up c-hr--large" />
                                    <h3 className="type-block-sub-headline2" >South American 16/17 Summer Campaign</h3>
                                </div>
                            </header>
                            <div className="sub-header" >
                                <h3 className="type-block-sub-headline2" >Every summer, heat takes over the conversation in Latin American cities. People talk on the streets, in taxis, in newspapers and on the TV . But nowhere does the heat rise as quickly and burn as fiercely as it does on social media.</h3>
                                <hr className="c-hr c-hr--seven-up c-hr--large" />
                            </div>
                        </div>
                    </section>
                </div>
                <div className="case-study-sections-wrapper" >
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Stunt / Hero Film / TV Spot</h3>
                            <hr className="c-hr c-hr--seven-up c-hr--large" />
                            <p className="type-body-copy case-study-limited-paragraph" >We created a custom algorithm that tracked in real time heat related mentions on social media across 8 LatAm countries. These mentions fueled a ‘social thermometer’. The thermometer was brought to life through an interactive billboard attached to a container filled with 7Up in Buenos Aires. Every time the needle reached the red zone, the container opened delivering ice cold 7Up for everyone!</p>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--high-limited u-no-padding-below-desktop" >
                            <figure className="case-study-figure" >
                                <div >
                                    <video autoPlay muted loop className="u-image-restraints js-controls-video" >
                                        <source src="/static/vids/case-studies/7-up/main.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >To bring it to life in digital we created social Heat Hacks - countless snackable pieces of content that brought to life 7UP’s summer promise: ‘It’s 7UP vs the Heat’.</h3>
                            <hr className="c-hr c-hr--seven-up c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-no-padding-below-desktop" >
                            <div >
                                <video autoPlay muted loop className="u-image-restraints js-controls-video" >
                                    <source src="/static/vids/case-studies/7-up/heat-hacks.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <div className="u-width-limiter u-width-limiter--low-limited" >
                            <h3 className="type-block-sub-headline2" >Tying the whole campaign together was our campaign micro-site and always on conversation across Latin America.</h3>
                            <hr className="c-hr c-hr--seven-up c-hr--large" />
                        </div>
                    </section>
                    <section className="o-section-std" >
                        <img src="/static/img/case-studies/seven-up/seven-up-devices.jpg" alt="7 Up website preview in several devices." className="u-image-full-width case-study-reveal-large-image" />
                    </section>
                </div>
                <div className=".case-studies-reel" >
                    <Link className="case-studies-reel__item case-studies-reel__item--tube-strike" to="/en/case-studies/tube-strike" >
                        <h4 className="type-block-sub-headline" >F*cking Tube Strike</h4>
                        <h6 className="type-subheadlines-buttons" >Reactive</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--made-by" to="/en/case-studies/made-by" >
                        <h4 className="type-block-sub-headline" >Made By</h4>
                        <h6 className="type-subheadlines-buttons" >Campaign</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--rjsmith" to="/en/case-studies/rjsmith" >
                        <h4 className="type-block-sub-headline" >RJ Smith</h4>
                        <h6 className="type-subheadlines-buttons" >Branding / Website</h6>
                    </Link>
                    <Link className="case-studies-reel__item case-studies-reel__item--more" to="/en/case-studies" >
                        <h4 className="type-block-sub-headline" >Blood, Sweat &amp; Tears</h4>
                        <h6 className="type-subheadlines-buttons" >See all our work.</h6>
                    </Link>
                </div>
                <div className="u-footer-spacer" ></div>
            </div>
        )
    }
}

export default (FullLanding);
